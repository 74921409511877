import { styled } from '@keypro/2nd-xp';
import { useLeftMenu, useRightMenu, useCenterMenu } from '@stores';
import { useMobile } from '@hooks/responsive';

const StyledMenuContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 56px);
    height: 100%;
    z-index: 1;
    right: 0;
    pointer-events: none;
`;

const SideMenuContainer = styled.div`
    width: 348px;
    & > * {
        pointer-events: auto;
        width: 348px;
    }
`;

const CenterMenuContainer = styled.div`
    flex-grow: 1;
    overflow-y: hidden;
    & > * {
        pointer-events: auto;
    }
`;

const StyledMobileMenuContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 50%;
    z-index: 1;
    bottom: 0;
    pointer-events: auto;
`;

/**
 * The AppMenus component is a container for the left, center, and right menus.
 * It has prefixed position and dimensions for each menu, and depending on context provided by MenuProvider, it renders the menu content.
 * @returns The AppMenus component
 */
const AppMenus = (): JSX.Element => {
    const leftMenuContext = useLeftMenu();
    const centerMenuContext = useCenterMenu();
    const rightMenuContext = useRightMenu();
    const isMobile = useMobile();

    if (isMobile && leftMenuContext.isMenuOpen)
        return (
            <StyledMobileMenuContainer id="menu-container">
                {leftMenuContext.isMenuOpen
                    ? leftMenuContext.menuContent
                    : null}
            </StyledMobileMenuContainer>
        );

    return (
        <StyledMenuContainer id="menu-container">
            {leftMenuContext.isMenuOpen ? (
                <SideMenuContainer id="left-menu">
                    {leftMenuContext.menuContent}
                </SideMenuContainer>
            ) : null}
            <CenterMenuContainer id="center-menu">
                {centerMenuContext.isMenuOpen
                    ? centerMenuContext.menuContent
                    : null}
            </CenterMenuContainer>
            <SideMenuContainer id="right-menu">
                {rightMenuContext.isMenuOpen
                    ? rightMenuContext.menuContent
                    : null}
            </SideMenuContainer>
        </StyledMenuContainer>
    );
};

export default AppMenus;
