import { sendGraphQLRequest } from '@utils';
import GetTypes from './queries/GetTypes.graphql';
import { TypeKind } from 'graphql';

interface SchemaResponse {
    __schema: {
        types: {
            name: string;
            kind: TypeKind;
            fields: {
                name: string;
                type: {
                    name: string;
                    kind: TypeKind;
                    ofType?: {
                        name: string;
                        kind: TypeKind;
                    };
                };
            }[];
        }[];
    };
}

/**
 * GraphQL type.
 */
export interface GraphQLObject {
    /** Type name */
    name: string;
    /** Type fields */
    fields: GraphQLObjectField[];
}

/**
 * GraphQL type field.
 */
export interface GraphQLObjectField {
    /** Field name */
    name: string;
    /** Field type */
    type: string;
    /** Is field a scalar? */
    isScalar: boolean;
    /** Object reference if not a scalar */
    typeObject?: GraphQLObject;
}

/**
 * Collection of all GraphQL types.
 */
export interface GraphQLTypeMap {
    [key: string]: GraphQLObject;
}

/**
 * Get all types from the GraphQL schema.
 * @returns Object containing all the types.
 */
export const getGraphQLTypes = async (): Promise<GraphQLTypeMap> => {
    const response = await sendGraphQLRequest<SchemaResponse>(GetTypes);
    const data = response.data.__schema;

    if (!data) throw new Error('No data found');

    const objMap: GraphQLTypeMap = {};

    data.types
        .filter((type) => type.kind === 'OBJECT')
        .forEach((type) => {
            const fields = type.fields.map((field) => {
                const fieldType = field.type;
                const isScalar =
                    fieldType.kind === 'SCALAR' ||
                    fieldType.ofType?.kind === 'SCALAR';

                return {
                    name: field.name,
                    type: fieldType.name ?? fieldType.ofType?.name,
                    isScalar,
                };
            });

            objMap[type.name] = {
                name: type.name,
                fields: fields,
            };
        });

    Object.keys(objMap).forEach((key) => {
        objMap[key].fields.forEach((field) => {
            if (!field.isScalar) {
                field.typeObject = objMap[field.type];
            }
        });
    });

    return objMap;
};
