import { useQuery } from '@tanstack/react-query';
import { fetchPlacementScales } from '@apis/map/placementScales';

/**
 * Placement scales hook to use react-query.
 */
export function useGetPlacementScales() {
    return useQuery({
        queryKey: ['map', 'getPlacementScales'],
        queryFn: fetchPlacementScales,
    });
}
