query getTelecomAreas($filter: TelecomAreaFilter) {
  getTelecomAreas(filter: $filter) {
    telecomAreas {
      id
      name
      remarks
      location
      areaSize
      perimeter
      exchangeArea {
        id
        name
      }
      telecomAreaType {
        id
        txt
      }
      createdBy
      createdTs
      updatedBy
      updatedTs
    }
  }
}
