import { getFreeAreas } from '@apis/keycore';
import { FormConfig, FormGroup } from '@form-configs';

const createConfig = (groups: FormGroup[] = []): FormConfig => ({
    gqlType: 'FreeAreaSearch',
    model: 'freeareasearch',
    groups,
    functions: {
        get: getFreeAreas,
    },
    queryCollection: 'freeAreas',
});

const config = createConfig();

export default config;
