query GetTelecomPremises($filter: TelecomPremisesFilter) {
  getTelecomPremises(filter: $filter) {
     telecomPremises{
      id
      identification
      capacity
      install_year
      name
      remarks
      survey_amount
      mapper
      historyDate
      location
      angle
      scale
      createdBy
      createdTs
      updatedBy
      updatedTs
      usageState { id, txt }
      plan { id, name }
      locationAccuracy { id, txt }
      mappingMethod { id, txt }
      owner { id, txt }
      renter { id, txt }
      deviceRoom { id, txt }
      safetyDistance { id, txt }
      heightAccuracy { id, txt }
      diagramConfirm { id, txt }
      address {
        id
        address {
          id
          streetno
          streetname
          streetnameAlt
          district
          city
          province
          municipality
          country
          zipcode
          location
        }
      }
      telecomArea { id, name }
      importance { id, txt }
      sourceSystem { id, txt }
      sourceSystemKey
      technicalId
      technicalInfo
      pole { id, pole_number }
      manhole { id, identification }
    }
  }
}