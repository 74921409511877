query GetFreeAreas($filter: FreeAreasFilter) {
  getFreeAreas(filter: $filter) {
    freeAreas {
      name
      id
      location
      perimeter
      color
      created_by
      created_date
      updated_by
      updated_date
      area_size
      plan {
        description
      }
      type {
        groupname
        id
        txt
      }
      state {
        id
        groupname
        logical_txt
        txt
        abbreviation
        num_value
        availability_start_ts
        orderno
        num_value2
        description
        category
        value
        value2
        additional_data
      }
    }
  }
}
