import { Document } from '@generated';
import { FormConfig } from '../types';
import { getDocuments } from '@apis/keycore';
import { t } from 'i18next';
import { Link } from '@components/FormBuilder/form-components';

const formatDownloadUrl = (data: unknown) => {
    const doc = data as Document;
    const url = `/proxy/keygwt_core.externaldocs/externaldoc_download?id=${doc.id}`;
    const text = doc.filename ?? t('download');

    return <Link data-tooltip-left={text} url={url} text={text} />;
};

const config: FormConfig = {
    gqlType: 'Document',
    model: 'externaldoc',
    labelFormatter: (doc) => doc?.filename as string,
    groups: [
        {
            name: 'objectData',
            fields: [
                {
                    name: 'downloadUrl',
                    valueFormatter: formatDownloadUrl,
                },
                { name: 'url', component: 'text' },
                { name: 'description', component: 'textarea' },
            ],
        },
    ],
    functions: {
        get: getDocuments,
    },
    queryCollection: 'documents',
};

export default config;
