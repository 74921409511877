import { GetTelecomPremisesQuery, TelecomPremise } from '@generated';
import GetTelecomPremises from './queries/GetTelecomPremises.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';

/**
 * Gets the telecom premises.
 * @param filter The filter to apply.
 * @returns The telecom premises.
 * @throws {Error} If the request fails.
 */
export const getTelecomPremises = async (filter: GraphQLFilter) => {
    try {
        const payload = !isFilterEmpty(filter)
            ? {
                  filter: filter,
              }
            : {
                  filter: null,
                  limit: 1,
              };
        const response = await sendGraphQLRequest<GetTelecomPremisesQuery>(
            GetTelecomPremises,
            payload,
        );

        if (!response.data.getTelecomPremises) {
            throw Error('No data returned');
        }

        return response.data.getTelecomPremises.telecomPremises?.filter(
            Boolean,
        ) as TelecomPremise[];
    } catch (error) {
        throw Error('Failed to get telecom premises: ' + error);
    }
};
