import { toSnakeCase } from '@components/FormBuilder/utils';
import { FormGroup } from '@form-configs';

/**
 * Convert groups field names to snake case.
 * @param group Group to convert.
 * @returns Converted group.
 */
const toSnakeCaseGroup = (group: FormGroup) => {
    return {
        name: group.name,
        fields: group.fields.map((field) => {
            if (typeof field === 'string') {
                return toSnakeCase(field);
            }
            return {
                ...field,
                name: field.custom ? field.name : toSnakeCase(field.name),
            };
        }),
    };
};

/**
 * Common location and mapping related fields.
 */
export const commonGroup: FormGroup = {
    name: 'common',
    fields: [
        {
            name: 'locationAccuracy',
            filter: {
                groupname: { eq: 'LOCATION_ACCURACY' },
            },
        },
        {
            name: 'heightAccuracy',
            filter: {
                groupname: { eq: 'H_ACCURACY' },
            },
        },
        'historyDate',
        {
            name: 'mappingMethod',
            filter: {
                groupname: { eq: 'MAPPING_METHOD' },
            },
        },
        'surveyAmount',
        'mapper',
    ],
};

/**
 * Common location and mapping related fields with snake case field names.
 */
export const commonGroupSnakeCase: FormGroup = toSnakeCaseGroup(commonGroup);

/**
 * Plan field with "include completed" checkbox.
 */
export const planGroup: FormGroup = {
    name: 'planGroup',
    fields: [
        'plan',
        {
            name: 'planIncludeCompleted',
            component: 'checkbox',
            custom: true,
        },
    ],
};

/**
 * Telecom area field with "bind automatically" checkbox.
 */
export const telecomAreaGroup: FormGroup = {
    name: 'telecomAreaGroup',
    fields: [
        'telecomArea',
        {
            name: 'telecomAreaBind',
            component: 'checkbox',
            custom: true,
        },
    ],
};

/**
 * Telecom area field with "bind automatically" checkbox with snake case field names.
 */
export const telecomAreaGroupSnakeCase: FormGroup =
    toSnakeCaseGroup(telecomAreaGroup);

/**
 * Data source fields.
 */
export const dataSourceGroup = {
    name: 'dataSource',
    fields: [
        {
            name: 'sourceSystem',
            filter: {
                groupname: { eq: 'SOURCE_SYSTEM' },
            },
        },
        'sourceSystemKey',
    ],
};

/**
 * Data source fields with snake case field names.
 */
export const dataSourceGroupSnakeCase = toSnakeCaseGroup(dataSourceGroup);

/**
 * Data source fields (alternate).
 */
export const altDataSourceGroup = {
    name: 'dataSource',
    fields: [
        {
            name: 'sourceSystem',
            filter: {
                groupname: { eq: 'SOURCE_SYSTEM' },
            },
        },
        { name: 'sourceKey', translationKey: 'sourceSystemKey' },
    ],
};

/**
 * Data source fields with snake case field names (alternate).
 */
export const altDataSourceGroupSnakeCase = toSnakeCaseGroup(altDataSourceGroup);

/**
 * Address fields.
 */
export const addressGroup: FormGroup = {
    name: 'addressGroup',
    fields: [
        { name: 'address', component: 'address' },
        {
            name: 'addressBind',
            component: 'checkbox',
            custom: true,
        },
    ],
};

/**
 * Address fields with snake case field names.
 */
export const addressGroupSnakeCase: FormGroup = toSnakeCaseGroup(addressGroup);
