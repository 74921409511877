import { search } from '@apis/keycore';
import { useQuery } from '@tanstack/react-query';

/**
 * Search hook config.
 */
export interface UseSearchConfig {
    enabled?: boolean;
}

/**
 * Hook for universal search query.
 */
export const useSearch = (
    term: string,
    { enabled = true }: UseSearchConfig = {},
) => {
    return useQuery({
        queryKey: ['search', term],
        queryFn: async () => search(term),
        enabled: !!term && enabled,
    });
};
