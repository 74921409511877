import { GetApartmentsQuery, Apartment, ApartmentFilter } from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetApartments from './queries/GetAddresses.graphql';

/**
 * Gets the apartments.
 * @returns Apartments.
 * @throws {Error} If the request fails.
 */
export const getApartments = async (
    filter?: ApartmentFilter,
): Promise<Apartment[]> => {
    try {
        const response = await sendGraphQLRequest<GetApartmentsQuery>(
            GetApartments,
            { filter: filter },
        );

        if (!response.data.getApartments) {
            throw Error('No data returned');
        }

        return response.data.getApartments.apartments?.filter(
            Boolean,
        ) as Apartment[];
    } catch (error) {
        throw Error('Failed to get apartments: ' + error);
    }
};
