query getPoints($filter: PointsFilter) {
    getPoints(filter: $filter) {
        points {
            id
            identification
            location
            angle
            scale
            color
            size
            description
            install_year
            survey_amount
            mapper
            history_date
            createdBy
            createdTs
            updatedBy
            updatedTs
            location_accuracy {
                id
            }
            mapping_method {
                id
            }
            haccuracy {
                id
            }
            safetyDistance {
                id
            }
            length
            distance
            point_type {
                id
                name
                label
                symbol_name
                category {
                    id
                }
            }
            state {
                id
            }
            plan {
                id
            }
            owner {
                id
            }
            renter {
                id
            }
        }
    }
}
