import { Checkbox, CheckboxProps, styled } from '@keypro/2nd-xp';
import { useState } from 'react';

const SmallLabel = styled.label`
    ${({ theme }) => theme.fonts['12px Regular']};
`;

/**
 * Props for FormCheckbox.
 */
export interface FormCheckboxProps extends CheckboxProps {
    /** Label for checkbox */
    label: string;
}

const FormCheckbox = (props: FormCheckboxProps): JSX.Element => {
    const { name, checked, label, ...rest } = props;
    const [state, setState] = useState<boolean>(!!checked);

    const toggleState = () => {
        setState(!state);
    };

    return (
        <>
            <Checkbox
                {...rest}
                name={name}
                id={name}
                style={{ borderRadius: 4 }}
                type="checkbox"
                checked={state}
                onChange={toggleState}
            />
            <SmallLabel htmlFor={name}>{label}</SmallLabel>
        </>
    );
};

export default FormCheckbox;
