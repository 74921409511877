import { useEffect, useRef, useState } from 'react';
import { styled, Icons } from '@keypro/2nd-xp';
import { useTranslation } from 'react-i18next';
import { handleOutsideClick } from '@components';

const LanguageSelectorWrapper = styled.label`
    display: flex;
    align-items: center;
    path {
        stroke: ${(props) => props.theme.colors.neutral[100]};
    }
    svg {
        width: 16px;
        height: 16px;
    }
    @media (max-width: 768px) {
        path {
            stroke: #6a6d78;
        }
    }
`;

const DropdownContainer = styled.div`
    position: relative;
    @media (max-width: 768px) {
        button {
            color: #6A6D78;
        }
`;

const DropdownButton = styled.button`
    // Styles for the button
    cursor: pointer;
    color: ${(props) => props.theme.colors.neutral[100]};
    display: flex;
    align-items: center;
    border: none;
    gap: 2px;
    background: none;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    svg {
        width: 16px;
        height: 16px;
    }
    @media (max-width: 768px) {
        color: #6a6d78;
    }
`;

const DropdownMenu = styled.ul`
    list-style: none;
    position: absolute;
    display: flex;
    width: 168px;
    padding: 8px 0;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.neutral[100]};
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
    margin-left: -70%;
    bottom: 100%;
`;

interface DropdownOptionProps {
    $isSelected: boolean;
}

const DropdownOption = styled.li<DropdownOptionProps>`
    width: 100%;
    button {
        ${(props) => props.theme.fonts['14px Regular']};
        width: 100%;
        display: flex;
        border: none;
        color: ${(props) => props.theme.colors.neutral[10]};
        padding: 6px 16px 6px 16px;
        align-items: center;
        align-self: stretch;
        cursor: pointer;
        background: ${(props) => (props.$isSelected ? '#D2E8FF' : 'none')};

        &:hover {
            background: ${(props) => props.theme.colors.neutral[90]};
        }
    }
`;

type Language = {
    name: string;
    code: string;
};

const languages = [
    { name: 'English', code: 'en-GB' },
    { name: 'Suomi', code: 'fi-FI' },
    { name: 'Svenska', code: 'sv-SE' },
    { name: 'Deutsch', code: 'de-GE' },
];

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const storedLanguageCode = localStorage.getItem('language');
    const languageCode = storedLanguageCode ?? i18n.language;
    const [language, setLanguage] = useState<Language>({
        name:
            languages.find((lang) => lang.code === languageCode)?.name ??
            'English',
        code: languageCode ?? 'en-GB',
    });
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    // Close the dropdown when clicking outside of it
    useEffect(() => {
        return handleOutsideClick(ref, setIsOpen, isOpen);
    }, [ref, isOpen]);

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (lang: Language) => {
        setLanguage(lang);
        localStorage.setItem('language', lang.code);
        i18n.changeLanguage(lang.code);
        setIsOpen(false);
    };

    useEffect(() => {
        if (storedLanguageCode) i18n.changeLanguage(storedLanguageCode);
    }, [storedLanguageCode, i18n]);

    return (
        <LanguageSelectorWrapper>
            <Icons.Globe />
            <DropdownContainer ref={ref} key={language.code}>
                <DropdownButton
                    onClick={handleButtonClick}
                    type="button"
                    data-testid="login-language-selector"
                >
                    {language.name}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            d="M11 6L8.5 9L6 6"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </DropdownButton>
                {isOpen && (
                    <DropdownMenu>
                        {languages.map((lang) => (
                            <DropdownOption
                                key={lang.code}
                                $isSelected={language.code === lang.code}
                            >
                                <button
                                    onClick={() => handleOptionClick(lang)}
                                    data-testid={`login-language-option-${lang.name}`}
                                >
                                    {lang.name}
                                </button>
                            </DropdownOption>
                        ))}
                    </DropdownMenu>
                )}
            </DropdownContainer>
        </LanguageSelectorWrapper>
    );
};

export default LanguageSelector;
