import { useContext, useState } from 'react';
import {
    Menu,
    MenuItem,
    MenuGroup,
    MenuDivider,
    Button,
    styled,
    Icons,
    DropdownMenu,
} from '@keypro/2nd-xp';
import { AccountElement, UserImage } from './UserWidgetParts';
import { AuthContext } from '@providers';
import { useTranslation } from 'react-i18next';

const LogoutItem = styled(MenuItem)`
    div {
        color: ${(props) => props.theme.colors.accents.red['10']};
    }
`;

const LogoutIcon = styled(Icons.LogOut)`
    path {
        stroke: ${(props) => props.theme.colors.accents.red['10']};
    }
`;

/**
 * UserWidget is a component that displays the user's initials and provides a dropdown menu with links to
 * @returns The UserWidget component
 */
export const UserWidget = () => {
    const { t } = useTranslation();
    const { logout } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const redirect = (url: string) => {
        window.open(url, '_blank', 'noopener');
    };

    return (
        <DropdownMenu
            align="right"
            button={
                <Button
                    kind="ghost"
                    data-tooltip={t('userTooltip')}
                    onClick={handleToggle}
                    data-testid="toolbar-user-widget"
                >
                    <UserImage style={{ width: '28px', height: '28px' }} />
                </Button>
            }
            className="user-widget"
        >
            <Menu>
                <MenuGroup title={t('account')}>
                    <AccountElement />
                </MenuGroup>
                <MenuDivider />
                <MenuItem
                    key="admin-site"
                    iconLeft={<Icons.Admin />}
                    leftContent={t('adminSite')}
                    rightContent={<Icons.ExternalLink />}
                    onClick={() => {
                        redirect('/admin');
                    }}
                    disabled={true}
                />
                <MenuItem
                    key="Keypro"
                    iconLeft={<Icons.K />}
                    leftContent={t('keyproLtd')}
                    rightContent={<Icons.ExternalLink />}
                    onClick={() => {
                        redirect('https://www.keypro.fi/');
                    }}
                />
                <MenuItem
                    key="about"
                    iconLeft={<Icons.About />}
                    leftContent={t('about')}
                    onClick={() => {
                        redirect('https://google.com/');
                    }}
                    disabled={true}
                />
                <MenuItem
                    key="theme"
                    iconLeft={<Icons.Moon2 />}
                    leftContent={t('darkMode')}
                    disabled={true}
                />
                <MenuDivider />
                <MenuGroup title={t('userManuals')}>
                    <MenuItem
                        key="keycom"
                        iconLeft={<Icons.Desktop />}
                        leftContent="KeyCom"
                        rightContent={<Icons.ExternalLink />}
                        onClick={() => {
                            redirect(
                                'https://support.keypro.fi/docs/introduction-to-keycom',
                            );
                        }}
                    />
                    <MenuItem
                        key="setup-guide"
                        iconLeft={<Icons.UserGuide />}
                        leftContent={t('setupGuide')}
                        rightContent={<Icons.ExternalLink />}
                        onClick={() => {
                            redirect(
                                'https://support.keypro.fi/docs/introduction-9',
                            );
                        }}
                    />
                    <MenuItem
                        key="release-notes"
                        iconLeft={<Icons.Rocket />}
                        leftContent={t('releaseNotes')}
                        rightContent={<Icons.ExternalLink />}
                        onClick={() => {
                            redirect(
                                'https://support.keypro.fi/docs/keycom-release-notes',
                            );
                        }}
                    />
                    <MenuItem
                        key="keymobile"
                        iconLeft={<Icons.Mobile />}
                        leftContent="KeyMobile"
                        rightContent={<Icons.ExternalLink />}
                        onClick={() => {
                            redirect('https://google.com/');
                        }}
                        disabled={true}
                    />
                </MenuGroup>
                <MenuDivider />
                <LogoutItem
                    key="logout"
                    iconLeft={<LogoutIcon />}
                    leftContent={t('logout')}
                    onClick={() => logout()}
                />
            </Menu>
        </DropdownMenu>
    );
};
