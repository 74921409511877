import { getTxtConstants } from '@apis/keycore';
import { FormConfig } from '../types';

const config: FormConfig = {
    gqlType: 'TxtConstant',
    model: 'txtconstant',
    groups: [],
    functions: {
        get: getTxtConstants,
    },
    queryCollection: 'txtConstants',
};

export default config;
