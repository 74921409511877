import { FormBuilder, getData } from '@components/FormBuilder';
import {
    FormData as FormConfigData,
    getTypeByModel,
    isModelSupported,
    getFormConfig,
    FormGroup,
    getTranslationKey,
    getLabel,
    FormField,
} from '@form-configs';
import {
    Button,
    Icons,
    MapContext,
    Select,
    SelectOptionItem,
    styled,
} from '@keypro/2nd-xp';
import {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { t } from 'i18next';
import { groupsSearch } from 'src/form-configs/search-form/GroupSearch';
import { GraphQLFilter } from '@apis/utils';
import { SearchArea, SearchResult } from '@generated';
import { infoToolsLayers, objectTypes } from '@components/utils';
import { Draw } from 'ol/interaction';
import Polygon from 'ol/geom/Polygon';
import { Coordinate } from 'ol/coordinate';
import VectorSource from 'ol/source/Vector';
import { fetchInfoTools } from '@apis/map';
import { useFormBuilder } from '@stores';

export type FilterObject = {
    [key: string]: unknown; // Adjust the type as necessary
};

/**
 * Props for the AdvancedSearch component
 */
interface AdvancedSearchProps {
    /** Is the advanced search tool open */
    isOpen?: boolean;
    /** The selected filters */
    selectedAdvancedFilters?: FilterObject;
    /** Function to set the selected filters */
    setSelectedAdvancedFilters?: (filters: FilterObject) => void;
    /** Function to set the model for the advanced search */
    setModelAdvancedSearch?: (model: string) => void;
    /** Function to set the advanced search state */
    setIsUsingAdvancedSearch?: (isUsingAdvancedSearch: boolean) => void;
    /** Function to set the advanced search data */
    setDataAdvancedSearch?: (data: SearchResult[]) => void;
    /** Search results to limit the search to */
    searchResults?: SearchResult[];
    /** Function to handle loading state */
    onLoading: (loading: boolean) => void;
    /** Function to handle the tool close */
    handleClose?: () => void;
}

/**
 * Advanced search component that allows users to search for objects based on selected groups
 * @param isOpen - The state of the tool
 * @param handleClose - The function to handle the tool close
 * @returns JSX.Element
 */
export const AdvancedSearch = forwardRef(
    (
        {
            isOpen = false,
            selectedAdvancedFilters,
            setSelectedAdvancedFilters,
            setModelAdvancedSearch,
            setIsUsingAdvancedSearch,
            setDataAdvancedSearch,
            searchResults,
            onLoading,
            handleClose,
        }: AdvancedSearchProps,
        ref,
    ) => {
        const { types } = useFormBuilder();
        const [model, setModel] = useState<string>('');
        const [data, setData] = useState<FormConfigData | undefined>(undefined);
        const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
        const [type, setType] = useState<string | null>(null);
        const searchString = 'Search';
        const advancedSearchRef = useRef<HTMLDivElement>(null);
        const drawingPolygonCoordinates = useRef<Coordinate[] | null>(null);

        const controller = useContext(MapContext);

        const handleDrawPolygon = () => {
            const vectorSource = new VectorSource();
            const draw = new Draw({
                source: vectorSource,
                type: 'Polygon',
            });

            // Store the draw interaction to remove it later
            const currentDrawInteraction = draw;

            draw.on('drawend', (event) => {
                const geometry = event.feature.getGeometry();
                if (geometry instanceof Polygon) {
                    const coords = geometry.getCoordinates()[0]; // Get coordinates of the polygon
                    drawingPolygonCoordinates.current = coords;
                }

                // Clean up: Remove the draw interaction after drawing is done
                controller?.map.removeInteraction(currentDrawInteraction);

                vectorSource.clear(); // Clear all features if you want to reset the source
            });

            // Add the draw interaction to the map
            controller?.map.addInteraction(draw);
        };

        const locationFilterActive = useMemo(() => {
            return selectedGroups.includes('location');
        }, [selectedGroups]);

        useImperativeHandle(ref, () => ({
            handleSubmit,
        }));

        // Options for the select input, memoized to optimize rendering
        const options: SelectOptionItem[] = useMemo(() => {
            if (isModelSupported(model)) {
                const gqlType = getTypeByModel(model);
                const tempType = gqlType.slice(0, -searchString.length);
                setType(gqlType);

                if (types[tempType]) {
                    const groupsForSearch: string[] = groupsSearch
                        .filter((group) => group.name === model)
                        .map((group) =>
                            group.fields.map((field: FormField | string) =>
                                typeof field === 'string' ? field : field.name,
                            ),
                        )?.[0];
                    groupsForSearch?.push('location');
                    return types[tempType].fields
                        .map((field) => {
                            if (groupsForSearch.includes(field.name)) {
                                const translationKey = getTranslationKey(
                                    gqlType,
                                    field.name,
                                );

                                const label = t(translationKey);

                                if (label) {
                                    return {
                                        value: field.name,
                                        label: t(translationKey),
                                    };
                                }
                            }
                        })
                        .filter(
                            (option): option is SelectOptionItem =>
                                option !== undefined,
                        );
                }
            }
            return [];
        }, [types, model]);

        // Effect to handle model change and set selected groups
        useEffect(() => {
            if (isModelSupported(model)) {
                const gqlType = getTypeByModel(model);
                setType(gqlType);
                const formConfig = getFormConfig(gqlType);
                // Initialize selected groups with all available groups
                setSelectedGroups(formConfig.groups.map((group) => group.name));
            }
        }, [model]); // Depend only on model

        useEffect(() => {
            if (selectedAdvancedFilters) {
                setSelectedGroups(Object.keys(selectedAdvancedFilters));
            }
        }, [selectedAdvancedFilters]);

        // Effect to fetch data based on the selected type
        useEffect(() => {
            if (type) {
                setData(undefined);
                getData(type, {}).then((results) => {
                    if (results?.length > 0) {
                        setData(results[0]);
                    }
                });
            }
        }, [type]); // Depend only on type

        // Filtered configuration for form fields based on selected groups
        const filteredConfig = useMemo(() => {
            const groups: FormGroup[] = [];
            groupsSearch.forEach((group: FormGroup) => {
                if (group.name === model) {
                    const newGroup: FormGroup = {
                        name: group.name,
                        fields: [],
                    };
                    group.fields.forEach((field) => {
                        if (
                            (typeof field !== 'string' &&
                                field.name &&
                                selectedGroups.includes(field.name)) ||
                            (typeof field === 'string' &&
                                selectedGroups.includes(field))
                        ) {
                            newGroup.fields.push(field);
                        }
                    });
                    groups.push(newGroup);
                }
            });
            return groups;
        }, [model, selectedGroups]);

        const handleEqOperator = (group: string, value: unknown) => {
            return group === 'location' ? { eq: '' } : { eq: value };
        };

        const handleSubmit = async (
            e: React.FormEvent,
            newFilters?: string[],
        ) => {
            e.preventDefault();
            onLoading(true);
            handleClose?.();
            const filter: FilterObject = {
                id: {
                    in: [],
                },
            };

            (newFilters ?? selectedGroups).forEach((group) => {
                if (data?.[group] !== undefined) {
                    const value = data[group];

                    if (Array.isArray(value)) {
                        // If the value is an array, use the 'in' operator
                        filter[group] = {
                            id: {
                                in: value, // Assuming you want to filter by the array of IDs
                            },
                        };
                    } else if (
                        typeof value === 'object' &&
                        group !== 'location'
                    ) {
                        // If it's a range, use the 'gte' and 'lte' operators
                        if (value) {
                            const max = (value as { max: number }).max;
                            if (max) {
                                filter[group] = {
                                    gte: (value as { min: number }).min,
                                    lte: (value as { max: number }).max,
                                };
                            } else {
                                filter[group] = {
                                    gte: (value as { min: number }).min,
                                };
                            }
                        } else {
                            filter[group] = {
                                gte: 0,
                            };
                        }
                    } else {
                        // If it's a single value, use the 'eq' operator
                        filter[group] = handleEqOperator(group, value);
                    }
                }
            });

            if (searchResults) {
                const realModel = model.substring(0, model.indexOf('search'));
                const ids = searchResults
                    .filter((result) => result.model_name === realModel)
                    .map((object) => object.id);
                filter.id = {
                    in: ids,
                };
            }

            setSelectedAdvancedFilters?.(filter);
            setModelAdvancedSearch?.(model);
            setIsUsingAdvancedSearch?.(true);

            try {
                const gqlType = getTypeByModel(model);
                const form = getFormConfig(gqlType);
                const realModel = form.model.substring(
                    0,
                    form.model.indexOf('search'),
                );

                const fetchData = await form.functions.get(
                    filter as GraphQLFilter,
                );

                const finalData: SearchResult[] = [];
                const shouldTakeFinalData =
                    newFilters?.includes('location') ||
                    (!newFilters && locationFilterActive);

                if (shouldTakeFinalData) {
                    const layer =
                        infoToolsLayers[
                            realModel as keyof typeof infoToolsLayers
                        ];
                    const locationFilter = {
                        layers: [layer],
                        searchArea: {
                            type: 'Feature',
                            properties: null,
                            geometry: {
                                coordinates: [
                                    drawingPolygonCoordinates.current,
                                ],
                                type: 'Polygon',
                            },
                        } as SearchArea,
                    };
                    const dataInLocation = await fetchInfoTools(locationFilter);

                    if (
                        selectedGroups.length === 1 ||
                        newFilters?.length === 1
                    ) {
                        const convertedData: SearchResult[] =
                            dataInLocation?.results.map((item) => {
                                return {
                                    id: parseInt(item.pk as string, 10),
                                    identification: getLabel(gqlType, item),
                                    location: item.location as string,
                                    model_name: realModel,
                                    angle: item.angle as number,
                                };
                            });
                        setDataAdvancedSearch?.(convertedData);
                    } else {
                        dataInLocation?.results?.forEach((item) => {
                            const found = fetchData.find(
                                (data) => data.id === item.pk,
                            );
                            if (found) {
                                finalData.push({
                                    id: parseInt(item.pk as string, 10),
                                    identification: getLabel(gqlType, item),
                                    location: item.location as string,
                                    model_name: realModel,
                                    angle: item.angle as number,
                                });
                            }
                        });
                        setDataAdvancedSearch?.(finalData);
                    }
                } else {
                    const convertedData: SearchResult[] = fetchData.map(
                        (item) => {
                            return {
                                id: parseInt(item.id as string, 10),
                                identification: getLabel(gqlType, item),
                                location: item.location as string,
                                model_name: realModel,
                                angle: item.angle as number,
                            };
                        },
                    );
                    setDataAdvancedSearch?.(convertedData);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                onLoading(false);
            }
        };
        const handleClearSelection = () => {
            setSelectedGroups([]); // Clear all selections
        };

        const handleCloseAdvancedSearch = () => {
            handleClose?.();
        };

        return (
            <StyledAdvancedSearch
                $isOpen={isOpen}
                data-testid="advanced-search"
                ref={advancedSearchRef}
            >
                <StyledHeader>
                    <StyledHeaderTop>
                        <StyledHeaderLeft data-testid="advanced-search-model-select">
                            <StyledHeaderTitle>
                                {t('filters')}
                            </StyledHeaderTitle>
                            <StyledHeaderFilter
                                placeholder="Select"
                                options={objectTypes}
                                onChangeValue={(value) => {
                                    if (typeof value === 'string') {
                                        setModel(value);
                                    }
                                }}
                            />
                        </StyledHeaderLeft>
                        <StyledHeaderRight
                            kind="ghost"
                            data-testid="advanced-search-close"
                            onClick={handleCloseAdvancedSearch}
                        >
                            <Icons.Cross2 />
                        </StyledHeaderRight>
                    </StyledHeaderTop>
                    <StyledDivider />
                    <StyledHeaderBot data-testid="advanced-search-group-select">
                        <StyledFilterTitle>{t('addFilter')}</StyledFilterTitle>
                        <Select
                            placeholder={t('advancedSearch.placeholder')}
                            isMultiSelect
                            isFilterable
                            options={options}
                            value={selectedGroups}
                            filterLabels={{
                                addedFiltersText: t(
                                    'advancedSearch.addedFilters',
                                ),
                                allFiltersText: t('advancedSearch.allFilters'),
                                clearSelectionText: t(
                                    'advancedSearch.clearSelection',
                                ),
                            }}
                            onChangeValue={(value) => {
                                setSelectedGroups(
                                    Array.isArray(value) ? value : [value],
                                );
                            }}
                            onClearSelection={handleClearSelection}
                        />
                    </StyledHeaderBot>
                    <StyledDivider />
                </StyledHeader>
                <form id="search-form" action="" onSubmit={handleSubmit}>
                    <StyledBody
                        $selectedGroups={selectedGroups.length}
                        $locationFilterActive={locationFilterActive}
                    >
                        {type && data && filteredConfig && (
                            <FormBuilder
                                gqlType={type}
                                data={data}
                                customGroup={filteredConfig}
                                onChangeValue={(name, value) => {
                                    setData((prev) => {
                                        if (prev) {
                                            return {
                                                ...prev,
                                                [name]: value,
                                            };
                                        }
                                        return prev;
                                    });
                                }}
                            />
                        )}
                    </StyledBody>
                </form>
                <StyledFooter>
                    <StyledDivider />
                    {locationFilterActive && (
                        <>
                            <StyledGeographicalLocation>
                                Geographical location
                                <StyledStyledGeographicalLocationChooseFromMap
                                    label={t('chooseFromMap')}
                                    kind="secondary"
                                    onClick={handleDrawPolygon}
                                />
                            </StyledGeographicalLocation>
                            <StyledDivider />
                        </>
                    )}
                    <StyledButtons>
                        <StyledBtnClearAll
                            label={t('clearAll')}
                            kind="secondary"
                            disabled={selectedGroups.length === 0}
                            onClick={handleClearSelection}
                            data-testid="advanced-search-clear-all"
                        />
                        <StyledBtnRight>
                            <StyledBtnCancel
                                label={t('cancel')}
                                kind="secondary"
                                onClick={handleCloseAdvancedSearch}
                                data-testid="advanced-search-cancel"
                            />
                            <StyledBtnApply
                                label={t('apply')}
                                type="submit"
                                form="search-form"
                                disabled={model === ''}
                                data-testid="advanced-search-apply"
                            />
                        </StyledBtnRight>
                    </StyledButtons>
                </StyledFooter>
            </StyledAdvancedSearch>
        );
    },
);

const StyledAdvancedSearch = styled.div<{ $isOpen?: boolean }>`
    display: ${(props) => (props.$isOpen ? 'block' : 'none')};
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 348px;
    max-height: calc(100vh - 100px);
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.neutral['50']};
    background-color: ${(props) => props.theme.colors.neutral['10']};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
`;

const StyledHeader = styled.div``;

const StyledHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
`;

const StyledHeaderLeft = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 8px;
    gap: 12px;
`;

const StyledHeaderTitle = styled.div`
    ${(props) => props.theme.fonts['16px Bold']}
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledHeaderFilter = styled(Select)`
    font-size: 13px;
    line-height: 16px;
    border-radius: 4px;
    color: ${(props) => props.theme.colors.neutral['90']};
    background-color: ${(props) => props.theme.colors.neutral['40']};
    width: 100px;
    & > button {
        & > div {
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
        }
    }
    & > ul {
        width: 168px;
    }
    & span {
        margin-top: 12px;
        & > svg:first-child {
            display: none;
        }
    }
`;

const StyledHeaderRight = styled(Button)`
    width: 32px;
    height: 32px;
    corlor: ${(props) => props.theme.colors.neutral['90']};
    & > svg {
        width: 16px;
        height: 16px;
    }
`;

const StyledHeaderBot = styled.div`
    padding: 14px 16px;
`;

const StyledFilterTitle = styled.div`
    ${(props) => props.theme.fonts['14px Bold']}
    color: ${(props) => props.theme.colors.neutral['100']};
    padding-bottom: 8px;
`;

const StyledBody = styled.div<{
    $selectedGroups?: number;
    $locationFilterActive?: boolean;
}>`
    display: ${(props) =>
        props.$selectedGroups && props.$selectedGroups > 0 ? 'block' : 'none'};
    max-height: ${(props) => {
        if (props.$locationFilterActive) {
            return 'calc(100vh - 428px)';
        }
        if (props.$selectedGroups && props.$selectedGroups > 5) {
            return 'calc(100vh - 320px)';
        }
        return 'auto';
    }};
    overflow-y: ${(props) =>
        props.$selectedGroups && props.$selectedGroups > 5 ? 'auto' : 'unset'};
    & > div {
        & > div {
            margin-right: 0;
            margin-left: 0;
            & > div {
                border-radius: 0;
                padding-top: 16px;
                & > div:first-child {
                    display: none;
                }
            }
        }
    }
`;

const StyledFooter = styled.div``;

const StyledGeographicalLocation = styled.div`
    padding: 16px;
    gap: 8px;
    display: grid;
`;

const StyledStyledGeographicalLocationChooseFromMap = styled(Button)`
    width: 100%;
`;

const StyledButtons = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
`;

const StyledBtnRight = styled.div`
    display: flex;
    gap: 8px;
`;

const StyledBtnClearAll = styled(Button)`
    background-color: ${(props) => props.theme.colors.neutral['10']};
`;

const StyledBtnCancel = styled(Button)`
    width: 100px;
`;

const StyledBtnApply = styled(Button)`
    width: 100px;
`;

const StyledDivider = styled.div`
    height: 2px;
    background-color: ${(props) => props.theme.colors.neutral['20']};
    width: 100%;
`;
