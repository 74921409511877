import i18n from '@i18n';
import { Button, Icons, styled, useToast } from '@keypro/2nd-xp';
import { t } from 'i18next';
import { DateTime } from 'luxon';

/**
 * AdditionalInfo component props.
 */
export interface AdditionalInfoProps {
    /** Database id */
    databaseId: string;
    /** Name of the person who created the object */
    createdByName: string;
    /** Email of the person who created the object */
    createdByEmail: string;
    /** Date when the object was created */
    createdDate: string;
    /** Name of the person who updated the object the last time */
    updatedByName?: string;
    /** Email of the person who updated the object the last time */
    updatedByEmail?: string;
    /** Date when the object was updated the last time */
    updatedDate?: string;
}

/**
 * The AdditionalInfo component displays the additional information of object.
 * @returns
 */
export const AdditionalInfo = (props: AdditionalInfoProps) => {
    const toast = useToast();

    const {
        databaseId,
        createdByName,
        createdByEmail,
        createdDate,
        updatedByName,
        updatedByEmail,
        updatedDate,
    } = props;

    const locale = i18n.language;

    const dateFormat: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    const localizedCreatedDate = DateTime.fromISO(createdDate, {
        locale: locale,
    }).toLocaleString(dateFormat);

    let localizedUpdatedDate = null;

    if (updatedDate) {
        localizedUpdatedDate = DateTime.fromISO(updatedDate, {
            locale: locale,
        }).toLocaleString(dateFormat);
    }

    const copyEmail = (email: string) => {
        navigator.clipboard
            .writeText(email)
            .then(() => {
                toast.show(t('emailCopied'));
            })
            .catch(() => {
                toast.show(t('emailCopyFailed'));
            });
    };

    return (
        <StyledAdditionalInfo>
            {createdDate && (
                <StyledInfo>
                    <StyledInfoTitle>{t('createdBy')}</StyledInfoTitle>
                    <StyledUser>
                        <StyledIcon>
                            <Icons.User />
                        </StyledIcon>
                        <StyledUserInfo>
                            <StyledName>{createdByName}</StyledName>
                            <StyledMailContainer>
                                <StyledMail
                                    kind="secondary"
                                    label={createdByEmail}
                                    data-tooltip-right={t('copyEmail')}
                                    data-testid="copy-created-by-email"
                                    onClick={() => {
                                        copyEmail(createdByEmail);
                                    }}
                                />
                            </StyledMailContainer>
                            <StyledDate>{localizedCreatedDate}</StyledDate>
                        </StyledUserInfo>
                    </StyledUser>
                </StyledInfo>
            )}
            {updatedDate && (
                <>
                    <StyledDivider />
                    <StyledInfo>
                        <StyledInfoTitle>{t('updatedBy')}</StyledInfoTitle>
                        <StyledUser>
                            <StyledIcon>
                                <Icons.User />
                            </StyledIcon>
                            <StyledUserInfo>
                                <StyledName>{updatedByName}</StyledName>
                                <StyledMailContainer>
                                    <StyledMail
                                        kind="secondary"
                                        label={updatedByEmail}
                                        data-tooltip-right={t('copyEmail')}
                                        data-testid="copy-updated-by-email"
                                        onClick={() => {
                                            copyEmail(updatedByEmail ?? '');
                                        }}
                                    />
                                </StyledMailContainer>
                                <StyledDate>{localizedUpdatedDate}</StyledDate>
                            </StyledUserInfo>
                        </StyledUser>
                    </StyledInfo>
                </>
            )}
            <StyledDb>
                <StyledDbCaption>{t('databaseId')}</StyledDbCaption>
                <StyledDbId>{databaseId}</StyledDbId>
            </StyledDb>
        </StyledAdditionalInfo>
    );
};

const StyledAdditionalInfo = styled.div`
    display: none;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.neutral['50']};
    background-color: ${({ theme }) => theme.colors.neutral['10']};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 30px;
    right: -10px;
    cursor: auto;
    &::after {
        display: inline-block;
        width: 14px;
        height: 4px;
        position: absolute;

        transform: translate(-50%, -10px);
        top: auto;
        left: 90%;
        bottom: 100%;

        content: url('data:image/svg+xml;utf8,<svg viewBox="0 0 15 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 2.00024L1.5 6.00024L13.5 6.00024L7.5 2.00024Z" fill="${(
            props,
        ) =>
            encodeURIComponent(
                props.theme.colors.neutral['10'],
            )}" /><path d="M0.5 5.50024L1.5 5.50024L7.5 1.50024L13.5 5.50024L14.5 5.50024" stroke="${(
            props,
        ) => encodeURIComponent(props.theme.colors.neutral['50'])}" /></svg>');
    }
`;

const StyledInfo = styled.div`
    padding: 8px 16px;
    white-space: nowrap;
    min-width: 256px;
`;

const StyledInfoTitle = styled.div`
    padding-top: 8px;
    padding-bottom: 8px;
    ${(props) => props.theme.fonts['12px Medium Uppercase']};
    color: ${(props) => props.theme.colors.neutral['80']};
`;

const StyledUser = styled.div`
    display: flex;
`;

const StyledIcon = styled.div`
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    & > svg {
        width: 16px;
        height: 16px;
    }
`;

const StyledUserInfo = styled.div``;

const StyledMailContainer = styled.div`
    position: relative;
    &:hover {
        & > div {
            display: flex;
        }
    }
`;

const StyledName = styled.p`
    margin-top: 0;
    margin-bottom: 0;
    ${(props) => props.theme.fonts['16px Medium']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledMail = styled(Button)`
    ${(props) => props.theme.fonts['14px Medium']};
    height: 20px;
    margin-left: -8px;
    background-color: transparent;
    &:hover {
        background-color: transparent !important;
    }
`;

const StyledDate = styled.p`
    margin-top: 8px;
    margin-bottom: 8px;
    height: 16px;
    ${(props) => props.theme.fonts['12px Medium']};
    color: ${(props) => props.theme.colors.neutral['80']};
`;

const StyledDivider = styled.div`
    height: 2px;
    background-color: ${(props) => props.theme.colors.neutral['20']};
    width: 100%;
`;

const StyledDb = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.neutral['20']};
`;

const StyledDbCaption = styled.div`
    padding: 6px 8px;
    ${(props) => props.theme.fonts['12px Medium Uppercase']};
    color: ${(props) => props.theme.colors.neutral['80']};
`;

const StyledDbId = styled.p`
    margin-top: 0;
    margin-bottom: 0;
    padding: 6px 8px;
    ${(props) => props.theme.fonts['14px Medium']};
    color: ${(props) => props.theme.colors.accents.blue['10']};
`;
