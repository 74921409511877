import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import fi from './locales/fi.json';

import formsEn from './locales/forms-en.json';
import formsFi from './locales/forms-fi.json';

i18n.use(initReactI18next).init({
    resources: {
        'en-GB': { translation: { ...en, ...formsEn } },
        'fi-FI': { translation: { ...fi, ...formsFi } },
    },
    lng: 'en-GB',
    interpolation: {
        escapeValue: false,
    },
    fallbackLng: 'en-GB',
});

export default i18n;
