import { Cable, GetCablesQuery } from '@generated';
import GetCables from './queries/GetCables.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';

/**
 * Gets the cables.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The cables.
 * @throws {Error} If the request fails.
 */
export const getCables = async (filter: GraphQLFilter) => {
    try {
        const payload = !isFilterEmpty(filter)
            ? {
                  filter: filter,
              }
            : {
                  filter: null,
                  limit: 1,
              };
        const response = await sendGraphQLRequest<GetCablesQuery>(
            GetCables,
            payload,
        );

        if (!response.data.getCables) {
            throw Error('No data returned');
        }

        return response.data.getCables.cables?.filter(Boolean) as Cable[];
    } catch (error) {
        throw Error('Failed to get cables: ' + error);
    }
};
