import { GetPolesQuery, Pole } from '@generated';
import GetPoles from './queries/GetPoles.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter } from '@apis/utils';

/**
 * Gets the poles.
 * @returns The poles.
 * @throws {Error} If the request fails.
 */
export const getPoles = async (filter: GraphQLFilter) => {
    try {
        const response = await sendGraphQLRequest<GetPolesQuery>(GetPoles, {
            filter: filter,
        });

        if (!response.data.getPoles) {
            throw Error('No data returned');
        }

        return response.data.getPoles.poles?.filter(Boolean) as Pole[];
    } catch (error) {
        throw Error('Failed to get poles: ' + error);
    }
};
