import {
    GetSpatialReferenceQuery,
    GetSpatialReferenceQueryVariables,
} from '@generated';
import { SpatialRef } from '@keypro/2nd-xp';
import { sendGraphQLRequest } from '@utils';
import GetSpatialReference from './queries/GetSpatialReference.graphql';

export const fetchSpatialReference = async (
    code: string,
): Promise<SpatialRef> => {
    const variables: GetSpatialReferenceQueryVariables = {
        code: code,
    };

    const response = await sendGraphQLRequest<GetSpatialReferenceQuery>(
        GetSpatialReference,
        variables,
    );
    const spatialRef = response.data.getSpatialReference?.spatialReference;
    return spatialRef as SpatialRef;
};
