import { themes, ThemeProvider } from '@keypro/2nd-xp';
import { LoginPage } from './pages/LoginPage';
import { useContext, useEffect } from 'react';
import './i18n/i18n';
import { useTranslation } from 'react-i18next';
import { MainPage, LoadingPage } from './pages';
import { AuthContext } from '@providers';

export default function App() {
    const { isAuthenticated, isLoading, user, authMethod } =
        useContext(AuthContext);
    const { i18n } = useTranslation();

    useEffect(() => {
        const storedLanguageCode = localStorage.getItem('language');
        if (storedLanguageCode) {
            i18n.changeLanguage(storedLanguageCode);
        }
    }, [i18n]);

    if (isLoading && authMethod !== 'default') {
        return <LoadingPage />;
    }

    const urlParams = window.location.search;
    if (urlParams.length > 0) {
        localStorage.setItem('urlParams', urlParams);
    }

    if (isAuthenticated) {
        const urlParams = localStorage.getItem('urlParams');
        if (urlParams) {
            const url = new URL(window.location.href);
            url.search = urlParams;
            window.history.replaceState({}, document.title, url.toString());
            localStorage.removeItem('urlParams');
        }
    }
    return (
        <ThemeProvider theme={themes.darkTheme}>
            {user && isAuthenticated ? <MainPage /> : <LoginPage />}
        </ThemeProvider>
    );
}
