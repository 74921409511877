import { DocumentsFilter, Document, GetDocumentsQuery } from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetDocuments from './queries/GetDocuments.graphql';

/**
 * Gets the documents.
 * @returns The documents.
 * @throws {Error} If the request fails.
 */
export const getDocuments = async (
    filter?: DocumentsFilter,
): Promise<Document[]> => {
    try {
        const response = await sendGraphQLRequest<GetDocumentsQuery>(
            GetDocuments,
            { filter: filter },
        );

        if (!response.data.getDocuments) {
            throw Error('No data returned');
        }

        return response.data.getDocuments.documents?.filter(
            Boolean,
        ) as Document[];
    } catch (error) {
        throw Error('Failed to get documents: ' + error);
    }
};
