import { GetAddressesQuery, Address } from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetAddresses from './queries/GetAddresses.graphql';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';

/**
 * Gets the addresses.
 * @returns Addresses.
 * @throws {Error} If the request fails.
 */
export const getAddresses = async (
    filter: GraphQLFilter,
): Promise<Address[]> => {
    try {
        const payload = !isFilterEmpty(filter)
            ? {
                  filter: filter,
              }
            : {
                  filter: null,
                  limit: 1,
              };
        const response = await sendGraphQLRequest<GetAddressesQuery>(
            GetAddresses,
            payload,
        );

        if (!response.data.getAddresses) {
            throw Error('No data returned');
        }

        return response.data.getAddresses.addresses?.filter(
            Boolean,
        ) as Address[];
    } catch (error) {
        throw Error('Failed to get addresses: ' + error);
    }
};
