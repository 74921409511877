import { getAddresses } from '@apis/keycore';
import { FormConfig, FormGroup } from '@form-configs';

const createConfig = (groups: FormGroup[] = []): FormConfig => ({
    gqlType: 'AddressSearch',
    model: 'addresssearch',
    groups,
    functions: {
        get: getAddresses,
    },
    queryCollection: 'addresses',
});

const config = createConfig();

export default config;
