import { create } from 'zustand';
import { getGraphQLTypes, GraphQLTypeMap } from '@apis/introspection';

interface FormBuilderState {
    types: GraphQLTypeMap;
    setTypes: (types: GraphQLTypeMap) => void;
}

const useFormBuilderStore = create<FormBuilderState>((set) => {
    const initializeTypes = async () => {
        const types = await getGraphQLTypes();
        set({ types });
    };

    initializeTypes();

    return {
        types: {} as GraphQLTypeMap,
        setTypes: (types) => set({ types }),
    };
});

export const useFormBuilder = () => {
    const { types } = useFormBuilderStore();
    return { types };
};
