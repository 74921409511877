import { useAuth0Auth } from './auth0';
import { useDefaultAuth } from './default';
import { User } from '@models/user';

export interface AuthMethodHook {
    /** Whether the user is authenticated */
    isAuthenticated: boolean;
    /** Whether the authentication process is in progress */
    isLoading: boolean;
    /** The user's details */
    user: User | null | undefined;
    /** Logs the user in */
    login: (username?: string, password?: string) => Promise<void>;
    /** Logs the user out */
    logout: () => Promise<void>;
}

/**
 * The available authentication methods.
 */
export enum AuthMethod {
    /** Okta Auth0 */
    Auth0 = 'auth0',
    /** Own in-house systems */
    Default = 'default',
}

/**
 * Hook that provides different authentication hooks to the application.
 */
export const useAuth = () => {
    return {
        default: useDefaultAuth(),
        auth0: useAuth0Auth(),
    };
};
