import { getServiceAreas } from '@apis/keycom';
import { FormConfig } from '../types';
import { Icons } from '@keypro/2nd-xp';

const config: FormConfig = {
    gqlType: 'ServiceArea',
    model: 'servicearea',
    icon: Icons.Area,
    groups: [
        {
            name: 'objectData',
            fields: [
                'name',
                {
                    name: 'category',
                    filter: {
                        groupname: { eq: 'SERVICE_A_CATEGORY' },
                    },
                },
                { name: 'areaSize', translationKey: 'area' },
                'perimeter',
                { name: 'remarks', component: 'textarea' },
            ],
        },
    ],
    functions: {
        get: getServiceAreas,
    },
    queryCollection: 'serviceAreas',
};

export default config;
