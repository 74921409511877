import { FormConfig } from '../types';
import { getFreeLines } from '@apis/keycore';
import { planGroup } from '../groups';
import { Icons } from '@keypro/2nd-xp';

const config: FormConfig = {
    gqlType: 'FreeLine',
    model: 'freeline',
    icon: Icons.Duct,
    groups: [
        {
            name: 'objectData',
            fields: [
                { name: 'identification', translationKey: 'identification' },
                'color',
                'line_width',
                {
                    name: 'type',
                    filter: {
                        groupname: { eq: 'FREE_LINE_TYPE' },
                    },
                    translationKey: 'type',
                },
                {
                    name: 'state',
                    filter: {
                        groupname: { eq: 'FREELINE_STATE' },
                    },
                },
                { name: 'description', component: 'textarea' },
            ],
        },
        planGroup,
    ],
    functions: {
        get: getFreeLines,
    },
    queryCollection: 'freeLines',
};

export default config;
