import { SearchArea } from '@generated';
import { useQuery } from '@tanstack/react-query';
import { fetchInfoTools } from '@apis/map/infoTool';

export interface UseGetInfoToolsOptions {
    layers?: string[];
    searchArea?: SearchArea;
}

export interface UseGetInfoToolsConfig {
    enabled?: boolean;
}

/**
 * Info tool hook to use react-query.
 */
export function useGetInfoTools(
    options: UseGetInfoToolsOptions,
    { enabled = true }: UseGetInfoToolsConfig = {},
) {
    const { layers, searchArea } = options;
    return useQuery({
        queryKey: ['map', 'infoTools', { layers, searchArea }],
        queryFn: async () => {
            return fetchInfoTools({
                layers: layers!,
                searchArea: searchArea!,
            });
        },
        enabled: !!layers && !!searchArea && enabled,
    });
}
