import { Icons, Switch, styled } from '@keypro/2nd-xp';
import React from 'react';
import { t } from 'i18next';

export interface PrintMenuSettingProps {
    icon?: React.ReactNode;
    title?: string;
    isOn?: boolean;
    isDisableSetting?: boolean;
    onChangeDisabled?: (disabled?: boolean) => void;
    children?: React.ReactNode;
}

export const PrintMenuSetting = ({
    icon,
    title,
    isOn,
    isDisableSetting,
    onChangeDisabled,
    children,
}: PrintMenuSettingProps) => {
    const [expanded, setExpanded] = React.useState(true);

    const btnAttribute: {
        'aria-expanded'?: boolean;
        'aria-controls'?: string;
        onClick?: () => void;
    } = {};

    const contentAttribute: {
        id?: string;
        'aria-hidden'?: boolean;
    } = {};

    const id = React.useId();

    btnAttribute['aria-expanded'] = expanded;
    btnAttribute['aria-controls'] = id;
    btnAttribute.onClick = () => setExpanded((prev) => !prev);

    contentAttribute.id = id;
    contentAttribute['aria-hidden'] = !expanded;

    return (
        <StyledPrintMenuSetting>
            <StyledPrintMenuSettingHeader>
                <StyledPrintMenuSettingHeaderLeft
                    onClick={() => setExpanded(!expanded)}
                >
                    <StyledPrintMenuSettingIcon>
                        {icon}
                    </StyledPrintMenuSettingIcon>
                    <StyledPrintMenuSettingTitle>
                        {title}
                    </StyledPrintMenuSettingTitle>
                </StyledPrintMenuSettingHeaderLeft>
                <StyledPrintMenuSettingHeaderRight>
                    <StyledPrintMenuSettingSwitch
                        data-testid={`printController-${title}-toggle`}
                    >
                        {isOn && (
                            <Switch
                                label={isDisableSetting ? t('on') : t('off')}
                                labelPosition="left"
                                onChangeChecked={(value) =>
                                    onChangeDisabled?.(value)
                                }
                            />
                        )}
                    </StyledPrintMenuSettingSwitch>

                    <StyledToggleIcon {...btnAttribute}>
                        <StyledShowIcon data-open={expanded}>
                            <Icons.Arrow />
                        </StyledShowIcon>
                    </StyledToggleIcon>
                </StyledPrintMenuSettingHeaderRight>
            </StyledPrintMenuSettingHeader>
            <StyledPrintMenuSettingContent
                $isOpen={expanded}
                {...contentAttribute}
            >
                {children}
            </StyledPrintMenuSettingContent>
        </StyledPrintMenuSetting>
    );
};

const StyledPrintMenuSetting = styled.div`
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.neutral['50']};
    padding-top: 8px;
    padding-bottom: 16px;
`;

const StyledPrintMenuSettingHeader = styled.div`
    display: flex;
    align-items: center;
`;

const StyledPrintMenuSettingHeaderLeft = styled.div`
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-top: 2px;
    padding-bottom: 2px;
    gap: 8px;
    flex: 1;
    cursor: pointer;
`;

const StyledPrintMenuSettingHeaderRight = styled.div`
    display: flex;
    align-items: center;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    z-index: 0;
`;

const StyledPrintMenuSettingIcon = styled.div`
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    & > svg {
        width: 16px;
        height: 16px;
    }
`;

const StyledPrintMenuSettingTitle = styled.div`
    padding-top: 6px;
    padding-bottom: 6px;
    ${(props) => props.theme.fonts['14px Bold']};
    color: ${(props) => props.theme.colors.neutral['90']};
`;

const StyledPrintMenuSettingSwitch = styled.div`
    padding-right: 16px;
`;

const StyledToggleIcon = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    color: ${(props) => props.theme.colors.neutral['80']};
    transition: background-color 200ms;
    width: 32px;
    height: 32px;
    &:hover {
        border-radius: 8px;
        background-color: ${(props) => props.theme.colors.neutral['50']};
    }
`;

const StyledShowIcon = styled.span`
    display: flex;
    & > svg {
        width: 24px;
        height: 24px;
        transform: rotate(0deg);
        transition: transform 200ms;
    }
    &[data-open='true'] > svg {
        transform: rotate(180deg);
    }
`;

const StyledPrintMenuSettingContent = styled.div<{ $isOpen?: boolean }>`
    display: ${({ $isOpen }) => ($isOpen ? 'grid' : 'none')};
    gap: 8px;
    padding-left: 16px;
    padding-right: 16px;
`;
