import { Icons, Input, Select, SelectOptionItem, styled } from '@keypro/2nd-xp';
import { PrintMenuSetting } from './PrintMenuSetting';
import { t } from 'i18next';
import React from 'react';

const cableOptions: SelectOptionItem[] = [
    {
        label: 'Cable 0001',
        value: '1',
    },
    {
        label: 'Cable 0002',
        value: '2',
    },
    {
        label: 'Cable 0003',
        value: '3',
    },
    {
        label: 'Cable 0004',
        value: '4',
    },
];

export const PrintMenuThemeLegendPart = () => {
    const [isDisableSetting, setIsDisableSetting] = React.useState(false);
    return (
        <PrintMenuSetting
            icon={<Icons.PrintThemeLegend />}
            title={t('themeLegend')}
            isOn={true}
            isDisableSetting={isDisableSetting}
            onChangeDisabled={() => setIsDisableSetting(!isDisableSetting)}
        >
            <StyledStyledPrintMenuSettingDescription>
                {t('themeLegendDescription')}
            </StyledStyledPrintMenuSettingDescription>
            <Select
                isFilterable
                options={cableOptions}
                defaultValue="1"
                disabled={!isDisableSetting}
            />
            <StyledPrintMenuSettingTextField>
                <StyledPrintMenuSettingTextFieldLabel>
                    {t('name')}
                    <StyledRequired>*</StyledRequired>
                </StyledPrintMenuSettingTextFieldLabel>
                <StyledInput disabled={!isDisableSetting} />
            </StyledPrintMenuSettingTextField>
            <StyledPrintMenuSettingTextField>
                <StyledPrintMenuSettingTextFieldLabel>
                    {t('name')}
                    <StyledRequired>*</StyledRequired>
                </StyledPrintMenuSettingTextFieldLabel>
                <StyledInput disabled={!isDisableSetting} />
            </StyledPrintMenuSettingTextField>
            <StyledPrintMenuSettingTextField>
                <StyledPrintMenuSettingTextFieldLabel>
                    {t('name')}
                    <StyledRequired>*</StyledRequired>
                </StyledPrintMenuSettingTextFieldLabel>
                <StyledInput disabled={!isDisableSetting} />
            </StyledPrintMenuSettingTextField>
        </PrintMenuSetting>
    );
};

const StyledStyledPrintMenuSettingDescription = styled.div`
    width: 195px;
    padding-bottom: 8px;
    ${(props) => props.theme.fonts['12px Regular']};
    color: ${(props) => props.theme.colors.neutral['80']};
`;

const StyledPrintMenuSettingTextField = styled.div`
    display: flex;
    align-items: center;
`;

const StyledInput = styled(Input)`
    width: 185px;
    height: 32px;
    margin-left: auto;
`;

const StyledPrintMenuSettingTextFieldLabel = styled.div`
    display: flex;
    gap: 4px;
    ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledRequired = styled.span`
    color: ${(props) => props.theme.colors.accents.red['10']};
`;
