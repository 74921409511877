query getTxtConstants($filter: TxtConstantsFilter $limit: Int) {
	getTxtConstants(filter: $filter limit: $limit) {
        txtConstants {
    	    id
            txt
            groupname
            orderno
            txt_value
            num_value
            num_value2
            num_value3
            num_value4
            value
            value2
            value3
            value4
            additional_data
        }
    }
}
