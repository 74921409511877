import { useQuery } from '@tanstack/react-query';
import { fetchMapConfig } from '@apis/map/mapConfig';

/**
 * Map config hook to use react-query.
 */
export function useGetMapConfig() {
    return useQuery({
        queryKey: ['map', 'getMapConfig'],
        queryFn: fetchMapConfig,
    });
}
