import { useQuery } from '@tanstack/react-query';
import { fetchSpatialReference } from '@apis/map/spatialReference';

/**
 * Spatial reference hook to use react-query.
 */
export const useGetSpatialReference = (code?: string) => {
    return useQuery({
        queryKey: ['map', 'getSpatialReference'],
        queryFn: () => fetchSpatialReference(code!),
        enabled: !!code,
    });
};
