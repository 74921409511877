query GetSplices($filter: SpliceFilter) {
  getSplices(filter: $filter) {
     splices{
      id
      identification
      remarks
      survey_amount
      mapper
      historyDate
      attenuation
      installYear
      angle
      scale
      type { id, txt }
      createdBy
      createdTs
      updatedBy
      updatedTs
      locationAccuracy { id, txt }
      heightAccuracy { id, txt }
      mappingMethod { id, txt }
      owner { id, txt }
      renter { id, txt }
      telecomArea { id, name }
      telecomPremise { id, identification }
      manhole { id, identification }
      placement { id, txt }
      address {
        id
        address {
          id
          streetno
          streetname
          streetnameAlt
          district
          city
          province
          municipality
          country
          zipcode
          location
        }
      }
      plan { id, name }
      productName { id, txt }
      location
      usageState { id, txt }
      safetyDistance { id, txt }
    }
  }
}
