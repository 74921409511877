import { LabelFormatter } from '@form-configs';
import {
    LoadingMask,
    Select,
    SelectOptionItem,
    styled,
    useCallbackRef,
} from '@keypro/2nd-xp';
import { useEffect, useState } from 'react';

/**
 * Props for FormSelect.
 */
export interface LazySelectProps {
    /** The value of the Select component */
    initialValue?: string;
    /** Whether the Select component allows multiple selections */
    isMultiSelect?: boolean;
    /** Function that returns the options for Select component */
    queryFunction: (
        labelFormatter?: LabelFormatter,
    ) => Promise<SelectOptionItem[]>;
    /** Function to format the option labels */
    labelFormatter?: LabelFormatter;
    /** Function that gets called when the value of the Select component changes */
    onChange?: (value: string | string[]) => void;
}

const Container = styled.div`
    position: relative;
`;

/**
 * A variant of Select component that lazily fetches the options using the provided query function.
 */
const LazySelect = (props: LazySelectProps): JSX.Element => {
    const {
        initialValue,
        queryFunction,
        isMultiSelect,
        labelFormatter,
        onChange,
    } = props;
    const [value, setValue] = useState<string[] | string>();
    const [options, setOptions] = useState<SelectOptionItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const cbQueryFunction = useCallbackRef(queryFunction);

    useEffect(() => {
        setIsLoading(true);

        cbQueryFunction(labelFormatter).then((newOptions) => {
            setOptions(newOptions);
            setValue(initialValue ?? '');
            setIsLoading(false);
        });
    }, [setValue, initialValue, labelFormatter, cbQueryFunction]);

    return (
        <Container
            onClick={(e) => {
                e.preventDefault(); // Prevent event from bubbling to the form
            }}
        >
            {isLoading && <LoadingMask iconSize={24} />}
            <Select
                options={options}
                defaultValue={isMultiSelect ? value : initialValue}
                isMultiSelect={isMultiSelect}
                onChangeValue={(newValue) => {
                    setValue(newValue);
                    onChange?.(newValue);
                }}
            />
        </Container>
    );
};

export default LazySelect;
