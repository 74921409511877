import { GetPointsQuery, Point } from '@generated';
import GetPoints from './queries/GetPoints.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter } from '@apis/utils';

/**
 * Gets the points.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The points.
 * @throws {Error} If the request fails.
 */
export const getPoints = async (filter: GraphQLFilter) => {
    try {
        const response = await sendGraphQLRequest<GetPointsQuery>(GetPoints, {
            filter: filter,
        });

        if (!response.data.getPoints) {
            throw Error('No data returned');
        }

        return response.data.getPoints.points?.filter(Boolean) as Point[];
    } catch (error) {
        throw Error('Failed to get points: ' + error);
    }
};
