import { SearchQuery, SearchQueryVariables, SearchResult } from '@generated';
import { sendGraphQLRequest } from '@utils';
import Search from './queries/Search.graphql';

export const search = async (term: string): Promise<SearchResult[]> => {
    try {
        const variables: SearchQueryVariables = {
            term: term,
        };

        const response = await sendGraphQLRequest<SearchQuery>(
            Search,
            variables,
        );

        return response.data.search?.searchResults?.filter(
            Boolean,
        ) as SearchResult[];
    } catch (error) {
        throw Error('Failed to search: ' + error);
    }
};
