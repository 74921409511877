import {
    GetTelecomAreasQuery,
    TelecomArea,
    TelecomAreaFilter,
} from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetTelecomAreas from './queries/GetTelecomAreas.graphql';

/**
 * Gets the telecom areas.
 * @returns {Promise<TelecomArea[]>} The telecom areas.
 * @throws {Error} If the request fails.
 */
export const getTelecomAreas = async (
    filter?: TelecomAreaFilter,
): Promise<TelecomArea[]> => {
    try {
        const response = await sendGraphQLRequest<GetTelecomAreasQuery>(
            GetTelecomAreas,
            {
                filter: filter,
            },
        );

        if (!response.data.getTelecomAreas) {
            throw Error('No data returned');
        }

        return response.data.getTelecomAreas.telecomAreas?.filter(
            Boolean,
        ) as TelecomArea[];
    } catch (error) {
        throw Error('Failed to get telecom areas: ' + error);
    }
};
