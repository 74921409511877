import { getManholes } from '@apis/keycom';
import { FormConfig, FormGroup } from '@form-configs';

const createConfig = (groups: FormGroup[] = []): FormConfig => ({
    gqlType: 'ManholeSearch',
    model: 'manholesearch',
    groups,
    functions: {
        get: getManholes,
    },
    queryCollection: 'manholes',
});

const config = createConfig();

export default config;
