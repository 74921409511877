query getPlans($filter: PlanFilter) {
  getPlans(filter: $filter) {
    plans {
      id
      name
      description
      begin_date
      expire_date
      created_by
      created_date
      updated_by
      updated_date
      location
      type {id, txt}
      network
      state {id, txt}
    }
  }
}
