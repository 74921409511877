import { MapContext, OpenLayersMap, Icons, styled } from '@keypro/2nd-xp';
import {
    MapConfig,
    OpenLayersMapProps,
} from '@keypro/2nd-xp/dist/components/map/OpenLayersMap';
import { useContext, useEffect, useState } from 'react';
import { ObjectOverlay } from '@components';
import { useGetSpatialReference, useGetMapConfig } from '@hooks/map';
import { t } from 'i18next';

const StyledLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    svg {
        width: 75px;
        height: 75px;
    }
`;

const AppMap = (): JSX.Element => {
    const controller = useContext(MapContext)!;
    const [mapProps, setMapProps] = useState<OpenLayersMapProps | null>(null);

    // Fetch mapconfig and spatialreference from backend
    const { data: mapConfig } = useGetMapConfig();
    const { data: spatialReference } = useGetSpatialReference(
        mapConfig?.options_v2.projection,
    );

    useEffect(() => {
        if (mapConfig && spatialReference) {
            const newMapProps: OpenLayersMapProps = {
                spatialReference: spatialReference,
                mapConfig: mapConfig as unknown as MapConfig,
                compassTooltip: t('compassTooltip'),
                compassResetTooltip: t('compassResetTooltip'),
            };
            setMapProps(newMapProps);
        }
    }, [spatialReference, mapConfig]);

    useEffect(() => {
        const onInitialize = () => {
            console.log('Map initialized');
            const srs = document.getElementById('srs');
            if (srs) {
                srs.innerHTML = controller.map
                    .getView()
                    .getProjection()
                    .getCode();
            }
        };

        controller.on('initialize', () => onInitialize);

        return () => {
            controller.off('initialize', onInitialize);
        };
    }, [controller]);

    // Load spinner while fetching mapConfig and spatialReference, show map when fetched
    if (!mapProps || !spatialReference) {
        return (
            <StyledLoading>
                <Icons.Spinner />
            </StyledLoading>
        );
    } else {
        return (
            <>
                <OpenLayersMap {...mapProps} />
                <ObjectOverlay />
            </>
        );
    }
};
export default AppMap;
