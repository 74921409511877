import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { Menu, MenuItem, MenuDivider, Switch, Icons } from '@keypro/2nd-xp';
import { t } from 'i18next';

const StyledMapViewSettingsPopper = styled.div`
    z-index: 1;
    padding: 20px;
    cursor: default;
`;

interface MapViewSettingsPopperProps {
    /** Name of the MapView */
    name: string | null;
    /** Reference element for the popper */
    referenceElement: HTMLElement | null;
    /** Function to close the popper */
    handleClose: () => void;
    /** Function to call when the item is deleted */
    onDelete: () => void;
    /** Function to set the editing state */
    setEditingView: React.Dispatch<React.SetStateAction<string | null>>;
}

/**
 * Popper for the settings of a MapView
 */
export const MapViewSettingsPopper = ({
    referenceElement: refElement,
    handleClose,
    onDelete,
    setEditingView,
}: MapViewSettingsPopperProps) => {
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(
        null,
    );
    const { styles, attributes } = usePopper(refElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    // the element has padding of 20px for extra mouseover area, so need to take that into account
                    offset: [
                        -20,
                        -(refElement ? refElement.offsetHeight + 20 : 0),
                    ],
                },
            },
        ],
    });
    return (
        <StyledMapViewSettingsPopper
            ref={setPopperElement}
            style={{
                ...styles.popper,
                zIndex: 1,
                padding: '20px',
                cursor: 'default',
            }}
            {...attributes.popper}
            onMouseLeave={handleClose}
        >
            <Menu>
                <MenuItem
                    leftContent={t('renameMapView')}
                    iconLeft={<Icons.Edit />}
                    onClick={() =>
                        setEditingView(refElement?.parentElement?.id ?? null)
                    }
                />
                <MenuItem
                    iconLeft={<Icons.Restore />}
                    leftContent={t('restoreMapView')}
                    disabled={true}
                />
                <MenuDivider />
                <MenuItem
                    iconLeft={<Icons.Locate />}
                    leftContent={t('rememberMyPosition')}
                    rightContent={<Switch disabled={true} />}
                    disabled={true}
                />
                <MenuItem
                    iconLeft={<Icons.Trash />}
                    leftContent={t('delete')}
                    onClick={() => onDelete()}
                />
            </Menu>
        </StyledMapViewSettingsPopper>
    );
};
