import { Icons, KeyCombo, Key } from '@keypro/2nd-xp';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

export const helpUrl = 'https://support.keypro.fi';

export const zoomToAreaHint = (
    <Trans i18nKey="zoomToAreaHint">
        <KeyCombo>
            <Key>Click</Key>
            <Icons.Plus />
            <Key>Drag</Key>
        </KeyCombo>
        to select an area to zoom into
    </Trans>
);

export const infoToolRectangleHint = (
    <Trans i18nKey="infoToolRectangleHint">
        <KeyCombo>
            <Key>Click</Key>
            <Icons.Plus />
            <Key>Drag</Key>
        </KeyCombo>
        to select an area
    </Trans>
);

export const infoToolPolygonHint = (
    <Trans i18nKey="infoToolPolygonHint">
        <Key>Click</Key> to select an area
        <Key>Double-Click</Key> to finish
    </Trans>
);

export const infoToolFreehandHint = (
    <Trans i18nKey="infoToolFreehandHint">
        <KeyCombo>
            <Key>Click</Key>
            <Icons.Plus />
            <Key>Drag</Key>
        </KeyCombo>
        to select an area
    </Trans>
);

export const infoToolAreaHint = (
    <Trans i18nKey="infoToolAreaHint">
        <Key>Click</Key> to select an area
    </Trans>
);

export const measureToolDistanceHint = (
    <Trans i18nKey="measureToolDistanceHint">
        <Key>Click</Key> to draw a line <Key>Double-Click</Key> to finish
    </Trans>
);

export const measureToolAreaHint = (
    <Trans i18nKey="measureToolAreaHint">
        <Key>Click</Key> to draw an area <Key>Double-Click</Key> to finish
    </Trans>
);

export const googleStreetsHint = (
    <Trans i18nKey="googleStreetsHint">
        <Key>Click</Key> to select a location for the street view
    </Trans>
);

export const getMeasureToolMenuProps = () => ({
    arrowTooltip: t('measureToolArrowTooltip'),
    distanceLabel: t('measureToolDistanceLabel'),
    distanceHint: measureToolDistanceHint,
    areaLabel: t('measureToolAreaLabel'),
    areaHint: measureToolAreaHint,
    tooltipInitialText: t('measureToolTooltipInitialText'),
    tooltipAreaLabel: t('measureToolTooltipAreaLabel'),
    tooltipPerimeterLabel: t('measureToolTooltipPerimeterLabel'),
});

export const getMoreMenuProps = () => ({
    'data-tooltip': t('moreMapOptionsTooltip'),
    googleMapsTitle: t('googleMapsTitle'),
    googleStreetsTitle: t('googleStreetsTitle'),
    googleStreetsHint: googleStreetsHint,
    copyMapLinkTitle: t('copyMapLinkTitle'),
    copyMapLinkMessage: t('copyMapLinkMessage'),
    refreshMapTitle: t('refreshMapTitle'),
});

export const getInfoToolMenuProps = () => ({
    arrowTooltip: t('infoToolArrowTooltip'),
    title: t('infoToolMenuTitle'),
    rectangleSelectionLabel: t('infoToolRectangleLabel'),
    rectangleSelectionHint: infoToolRectangleHint,
    mapViewSelectionLabel: t('infoToolMapViewLabel'),
    polygonSelectionLabel: t('infoToolPolygonLabel'),
    polygonSelectionHint: infoToolPolygonHint,
    freehandSelectionLabel: t('infoToolFreehandLabel'),
    freehandSelectionHint: infoToolFreehandHint,
    areaSelectionLabel: t('infoToolAreaLabel'),
    areaSelectionHint: infoToolAreaHint,
});

export const getLocateMeButtonProps = () => ({
    'data-tooltip': t('locateMeTooltip'),
    label: t('locateMe'),
    responsiveLabel: true,
    responsiveBreakpoint: 1515,
});

export const getZoomToAreaButtonProps = () => ({
    'data-testid': 'toolbar-zoomToArea',
    'data-tooltip': t('zoomToAreaTooltip'),
    label: t('zoomToArea'),
    $hint: zoomToAreaHint,
    responsiveLabel: true,
    responsiveBreakpoint: 1515,
});
