import {
    Button,
    Checkbox,
    Coordinates,
    Icons,
    Input,
    MapContext,
    PositionOverlay,
    Select,
    SelectOptionItem,
    styled,
} from '@keypro/2nd-xp';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MapBrowserEvent } from 'ol';
import { t } from 'i18next';
import { useGetTxtConstants } from '@hooks/keycore/txtConstant';
import proj4 from 'proj4';

interface CoordinatesToolProps {
    isOpenTool?: boolean;
    onActionClickTool?: (isOpen: boolean) => void;
    onChangeCoordinateSystem?: (coordinateSystemName: string) => void;
}

/**
 * The CoordinatesTool component displays the coordinates tool.
 * @param isOpenTool - The state of the tool
 * @param onActionClickTool - The function to handle the tool action
 * @param onChangeCoordinateSystem - The function to handle the coordinate system change
 * @returns JSX.Element
 */
export const CoordinatesTool = ({
    isOpenTool,
    onActionClickTool,
    onChangeCoordinateSystem,
}: CoordinatesToolProps) => {
    const controller = useContext(MapContext)!;

    const [isHighlight, setIsHighlight] = useState(false);

    const [isShowCursorCoordinates, setIsShowCursorCoordinates] =
        useState(true);

    // Lon, Lat
    const [currentUserPosition, setCurrentUserPosition] = useState<
        Coordinates | undefined
    >(undefined);

    const [position, setPosition] = useState<Coordinates | undefined>(
        undefined,
    );

    const [coordinatesSystem, setCoordinatesSystem] = useState('4');

    const coordinatesSystemData = useGetTxtConstants({
        filter: {
            groupname: { eq: 'KEYCORE_IMPORT_SRS' },
        },
    });

    const coordinatesSystemList = useMemo(() => {
        return coordinatesSystemData.data ?? [];
    }, [coordinatesSystemData]);

    const coordinatesSystemItems: SelectOptionItem[] = coordinatesSystemList
        .filter((item) => item?.orderno !== null)
        .map((item) => {
            return {
                label: item?.txt ?? '',
                value: item?.orderno?.toString() ?? '',
            };
        });

    const getSelectedCoordinateSystem = useCallback(
        (coordinatesSystemValue: string) => {
            return coordinatesSystemList.find(
                (item) => item?.orderno?.toString() === coordinatesSystemValue,
            );
        },
        [coordinatesSystemList],
    );

    const previousCoordinateSystem = useMemo(() => {
        return coordinatesSystemList.find(
            (item) => item?.orderno?.toString() === '4',
        );
    }, [coordinatesSystemList]);

    const convertCurrentUserFromWSG84 = useMemo(() => {
        if (!currentUserPosition) {
            return;
        }
        return controller.convertFromWSG84([
            currentUserPosition[1],
            currentUserPosition[0],
        ]);
    }, [controller, currentUserPosition]);

    const transformCoordinates = useMemo(() => {
        return (coordinates: Coordinates, toCoordinateSystem: string) => {
            if (
                !coordinates ||
                !getSelectedCoordinateSystem(toCoordinateSystem) ||
                !previousCoordinateSystem ||
                !getSelectedCoordinateSystem?.(toCoordinateSystem)
                    ?.additional_data ||
                !previousCoordinateSystem.additional_data
            ) {
                return;
            }

            const newCoordinates = proj4(
                previousCoordinateSystem?.additional_data ?? '',
                getSelectedCoordinateSystem?.(toCoordinateSystem)
                    ?.additional_data ?? '',
                coordinates,
            );

            return newCoordinates;
        };
    }, [getSelectedCoordinateSystem, previousCoordinateSystem]);

    useEffect(() => {
        const handleMouseMovePosition = (event: MapBrowserEvent<UIEvent>) => {
            setPosition([event.coordinate[0], event.coordinate[1]]);
        };

        if (isShowCursorCoordinates) {
            controller.on('mouseMove', handleMouseMovePosition);
        } else {
            controller.on('mouseClick', handleMouseMovePosition);
        }
        return () => {
            controller.off('mouseMove', handleMouseMovePosition);
        };
    }, [controller, isShowCursorCoordinates, position]);

    useEffect(() => {
        controller.on('mouseMove', (event: MapBrowserEvent<UIEvent>) => {
            const mousePosition: Coordinates = [
                event.coordinate[0],
                event.coordinate[1],
            ];
            if (mousePosition) {
                document.getElementById('coordinates')!.innerHTML =
                    transformCoordinates?.(
                        mousePosition,
                        coordinatesSystem,
                    )?.[0].toFixed(2) +
                    ', ' +
                    transformCoordinates(
                        mousePosition,
                        coordinatesSystem,
                    )?.[1].toFixed(2);
            }
        });
    }, [controller, coordinatesSystem, transformCoordinates]);

    return (
        <>
            <StyledCoordinatesTool $isOpenTool={isOpenTool}>
                <StyledHeader>
                    <StyledTitle>{t('coordinateTooltip')}</StyledTitle>
                    <StyledCloseBtn
                        kind="ghost"
                        onClick={() => onActionClickTool?.(!isOpenTool)}
                    >
                        <Icons.Cross2 />
                    </StyledCloseBtn>
                </StyledHeader>
                <StyledBody>
                    <StyledDivider />
                    <StyledSystem>
                        <StyledSystemLabel>
                            {t('displayCoordinateSystem')}
                        </StyledSystemLabel>
                        <StyledSystemSelect
                            options={coordinatesSystemItems}
                            onChangeValue={(value) => {
                                if (!Array.isArray(value)) {
                                    setCoordinatesSystem(value);
                                    onChangeCoordinateSystem?.(
                                        getSelectedCoordinateSystem?.(value)
                                            ?.txt ?? '',
                                    );
                                    if (position) {
                                        document.getElementById(
                                            'coordinates',
                                        )!.innerHTML =
                                            transformCoordinates?.(
                                                position,
                                                value,
                                            )?.[0].toFixed(2) +
                                            ', ' +
                                            transformCoordinates(
                                                position,
                                                value,
                                            )?.[1].toFixed(2);
                                    }
                                }
                            }}
                            defaultValue={coordinatesSystem}
                        />
                    </StyledSystem>
                    <StyledDivider />
                    <StyledLocation>
                        <StyledSystemLabelBtn>
                            <StyledCommonTitle>
                                {t('location')}
                            </StyledCommonTitle>
                            <StyledLocationBtn
                                kind="secondary"
                                iconPosition="left"
                                label={t('locateMe')}
                                onClick={() => {
                                    navigator.geolocation.getCurrentPosition(
                                        (position) => {
                                            const { latitude, longitude } =
                                                position.coords;
                                            setCurrentUserPosition([
                                                latitude,
                                                longitude,
                                            ]);
                                        },
                                    );
                                }}
                            >
                                <Icons.Locate />
                            </StyledLocationBtn>
                        </StyledSystemLabelBtn>
                        <StyledLocationTextField>
                            <StyledLocationInputLabel>
                                {t('lat')}
                            </StyledLocationInputLabel>
                            <StyledLocationInput
                                iconEnd={<StyledDegree />}
                                disabled
                                inputProps={{
                                    value: currentUserPosition?.[0] ?? 0,
                                }}
                            />
                        </StyledLocationTextField>
                        <StyledLocationTextField>
                            <StyledLocationInputLabel>
                                {t('lon')}
                            </StyledLocationInputLabel>
                            <StyledLocationInput
                                iconEnd={<StyledDegree />}
                                disabled
                                inputProps={{
                                    value: currentUserPosition?.[1] ?? 0,
                                }}
                            />
                        </StyledLocationTextField>
                    </StyledLocation>
                    <StyledDivider />
                    <StyledInfo>
                        <StyledCommonTitle>
                            {
                                getSelectedCoordinateSystem?.(coordinatesSystem)
                                    ?.txt
                            }
                        </StyledCommonTitle>
                        <StyledCoordinatesInfo>
                            <StyledCoordinates>
                                <StyledCoordinatesCaption>
                                    X
                                </StyledCoordinatesCaption>
                                <StyledCoordinatesValue>
                                    {position &&
                                        transformCoordinates(
                                            position,
                                            coordinatesSystem,
                                        )?.[0].toFixed(2)}
                                </StyledCoordinatesValue>
                            </StyledCoordinates>
                            <StyledCoordinates>
                                <StyledCoordinatesCaption>
                                    Y
                                </StyledCoordinatesCaption>
                                <StyledCoordinatesValue>
                                    {position &&
                                        transformCoordinates(
                                            position,
                                            coordinatesSystem,
                                        )?.[1].toFixed(2)}
                                </StyledCoordinatesValue>
                            </StyledCoordinates>
                        </StyledCoordinatesInfo>
                    </StyledInfo>
                    <StyledShowCursor>
                        <StyledShowCursorCheckBox>
                            <StyledCheckBox
                                checked={isShowCursorCoordinates}
                                onChange={() =>
                                    setIsShowCursorCoordinates(
                                        !isShowCursorCoordinates,
                                    )
                                }
                            />
                            <StyledCheckBoxTitle>
                                {t('showCursorCoordinates')}
                            </StyledCheckBoxTitle>
                        </StyledShowCursorCheckBox>
                        <StyledShowCursorDesc>
                            {t('showCursorCoordinatesDesc')}
                        </StyledShowCursorDesc>
                    </StyledShowCursor>
                    <StyledDivider />
                </StyledBody>
                <StyledFooter>
                    <StyledFooterButton
                        kind="secondary"
                        label="Highlight"
                        onClick={() => setIsHighlight(!isHighlight)}
                    />
                    <StyledFooterButton
                        kind="primary"
                        label="Go!"
                        onClick={() => {
                            if (!convertCurrentUserFromWSG84) {
                                return;
                            }
                            controller.map
                                .getView()
                                .setCenter(convertCurrentUserFromWSG84);
                        }}
                    />
                </StyledFooter>
            </StyledCoordinatesTool>
            <PositionOverlay
                position={
                    isHighlight && convertCurrentUserFromWSG84
                        ? [
                              convertCurrentUserFromWSG84[0],
                              convertCurrentUserFromWSG84[1],
                          ]
                        : [0, 0]
                }
                positioning="center-center"
            >
                <Icons.LocationMark style={{ width: 40, height: 40 }} />
            </PositionOverlay>
        </>
    );
};

const StyledCoordinatesTool = styled.div<{ $isOpenTool?: boolean }>`
    box-sizing: border-box;
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    position: absolute;
    background-color: ${(props) => props.theme.colors.neutral['10']};
    border: 1px solid ${(props) => props.theme.colors.neutral['50']};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    width: 300px;
    bottom: 60px;
    right: -4px;
    display: ${(props) => (props.$isOpenTool ? 'block' : 'none')};
`;

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 8px;
    padding-bottom: 8px;
`;

const StyledTitle = styled.span`
    font: ${(props) => props.theme.fonts['16px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledCloseBtn = styled(Button)`
    color: ${(props) => props.theme.colors.neutral['90']};
    width: 32px;
    height: 32px;
    & > svg {
        width: 16px;
        height: 16px;
    }
`;

const StyledBody = styled.div`
    padding-left: 16px;
    padding-right: 16px;
`;

const StyledSystem = styled.div`
    padding-top: 14px;
    padding-bottom: 14px;
`;

const StyledSystemLabel = styled.div`
    font: ${(props) => props.theme.fonts['14px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
    padding-bottom: 8px;
`;

const StyledSystemSelect = styled(Select)`
    width: 100%;
`;

const StyledLocation = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
    display: grid;
    gap: 8px;
`;

const StyledSystemLabelBtn = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledCommonTitle = styled.div`
    padding-top: 6px;
    padding-bottom: 6px;
    font: ${(props) => props.theme.fonts['14px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledLocationBtn = styled(Button)`
    & > svg {
        color: ${(props) => props.theme.colors.accents.blue['10']};
    }
`;

const StyledLocationTextField = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledLocationInputLabel = styled.span`
    font: ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
`;

const StyledLocationInput = styled(Input)`
    width: 192px;
`;

const StyledInfo = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
`;

const StyledCoordinatesInfo = styled.div`
    display: flex;
    gap: 16px;
`;

const StyledCoordinates = styled.div`
    display: flex;
    gap: 8px;
`;

const StyledCoordinatesCaption = styled.div`
    font: ${(props) => props.theme.fonts['14px Bold']};
    color: ${(props) => props.theme.colors.neutral['80']};
`;

const StyledCoordinatesValue = styled.div`
    font: ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
`;

const StyledShowCursor = styled.div`
    padding-bottom: 16px;
`;

const StyledShowCursorCheckBox = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

const StyledCheckBox = styled(Checkbox)`
    border-radius: 4px;
    width: 16px;
    height: 16px;
`;

const StyledCheckBoxTitle = styled.span`
    font: ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['100']};
    padding-top: 6px;
    padding-bottom: 6px;
`;

const StyledShowCursorDesc = styled.div`
    font: ${(props) => props.theme.fonts['12px Regular']};
    color: ${(props) => props.theme.colors.neutral['80']};
    padding-left: 32px;
`;

const StyledFooter = styled.div`
    padding: 16px 16px 8px 16px;
    display: flex;
    gap: 8px;
`;

const StyledFooterButton = styled(Button)`
    width: 130px;
`;

const StyledDivider = styled.div`
    height: 2px;
    background-color: ${(props) => props.theme.colors.neutral['20']};
    width: 100%;
`;

const StyledDegree = styled.div`
    width: 3px;
    height: 3px;
    color: ${(props) => props.theme.colors.neutral['90']};
    border: 1px solid rgba(143, 146, 155, 1);
    border-radius: 50%;
    margin-bottom: 6px;
`;
