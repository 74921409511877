import { styled } from '@keypro/2nd-xp';
import { useState } from 'react';

/**
 * Props for RangeNumberInput.
 */
export interface RangeNumberInputProps {
    /** The initial minimum value of the range */
    initialMinValue?: number;
    /** The initial maximum value of the range */
    initialMaxValue?: number;
    /** Function that gets called when the value of the range changes */
    onChange?: (value: {
        min: number | undefined;
        max: number | undefined;
    }) => void;
    /** The symbol to display after the number */
    symbol?: string;
}

/**
 * A component that allows users to input a range of numbers.
 * @returns The JSX element for the component.
 */
const RangeNumberInput = ({
    initialMinValue,
    initialMaxValue,
    onChange,
    symbol,
}: RangeNumberInputProps): JSX.Element => {
    const [minValue, setMinValue] = useState<number | undefined>(
        initialMinValue,
    );
    const [maxValue, setMaxValue] = useState<number | undefined>(
        initialMaxValue,
    );

    const handleMinChange = (value: string) => {
        const parsedValue = parseFloat(value) || undefined;
        setMinValue(parsedValue);
        onChange?.({
            min: parsedValue ?? 0,
            max: maxValue,
        });
    };

    const handleMaxChange = (value: string) => {
        const parsedValue = parseFloat(value) || undefined;
        setMaxValue(parsedValue);
        onChange?.({
            min: minValue ?? 0,
            max: parsedValue,
        });
    };

    return (
        <StyledRangeNumberInput data-testid="range-number-input">
            <StyledContainer>
                <StyledNumberInput
                    value={minValue !== undefined ? minValue.toString() : ''}
                    type="number"
                    placeholder="min"
                    onChange={(e) => handleMinChange(e.target.value)}
                    data-testid="range-number-input-min"
                />
                <StyledSymbol>{symbol}</StyledSymbol>
            </StyledContainer>
            <StyledHorizontalDivider />
            <StyledContainer>
                <StyledNumberInput
                    value={maxValue !== undefined ? maxValue.toString() : ''}
                    type="number"
                    placeholder="max"
                    onChange={(e) => handleMaxChange(e.target.value)}
                    data-testid="range-number-input-max"
                />
                <StyledSymbol>{symbol}</StyledSymbol>
            </StyledContainer>
        </StyledRangeNumberInput>
    );
};

export default RangeNumberInput;

const StyledRangeNumberInput = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const StyledHorizontalDivider = styled.div`
    border: 1px solid ${(props) => props.theme.colors.neutral['60']};
    transform: rotate(90deg);
    height: 6px;
`;

const StyledContainer = styled.div`
    display: inline-flex;
    position: relative;
    max-width: 92px;
    height: 32px;
`;

const StyledNumberInput = styled.input`
    height: 100%;
    max-width: 66px;
    text-align: left;
    background: none;
    color: inherit;
    font: inherit;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    align-items: center;
    background-color: ${(props) => props.theme.colors.neutral['20']};
    border: 1px solid ${(props) => props.theme.colors.neutral['60']};

    &:hover {
        border-color: ${(props) => props.theme.colors.neutral['70']};
    }

    &:focus,
    &:focus-within {
        border-color: ${(props) => props.theme.colors.accents.blue['10']};
        box-shadow: ${(props) =>
            `0px 0px 0px 3px ${props.theme.colors.accents.blue['10-transparent-30']}`};
        outline: none;
    }

    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const StyledSymbol = styled.span`
    position: absolute;
    right: 8px;
    top: 60%;
    transform: translateY(-50%);
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    pointer-events: none;
    user-select: none;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
`;
