query Search($term: String!) {
  search(term: $term) {
    metadata {
      totalCount
    }
    searchResults {
      id
      location
      identification
      model_name
      model_identity
      angle
    }
  }
}
