import { Address } from '@generated';
import { Button, Icons, styled } from '@keypro/2nd-xp';
import { t } from 'i18next';

/**
 * Props for the Address component.
 */
export interface AddressProps {
    /** Address data */
    data?: Address;
}

const Container = styled.div`
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.neutral['30']};

    min-height: 60px;

    display: flex;
    position: relative;
    flex-direction: column;
    padding: 8px 12px 8px 12px;

    div {
        position: absolute;
        top: 4px;
        right: 4px;
        display: flex;
        gap: 2px;
    }
`;

const AddressButton = styled(Button)`
    width: 32px;
    height: 32px;

    svg {
        width: 16px;
        height: 16px;
    }
`;

/**
 * Component for showing address in a form.
 */
const AddressInput = ({ data: address }: AddressProps): JSX.Element => {
    const streetname = address?.streetname ?? '';
    const streetno = address?.streetno ?? '';
    const zipcode = address?.zipcode ?? '';
    const city = address?.city ?? '';

    const streetRow = (streetname + ' ' + streetno).trim();
    const cityRow = (zipcode + ' ' + city).trim();
    const countryRow = address?.country ?? '';

    return (
        <Container>
            <div>
                <AddressButton kind="ghost" data-tooltip={t('editAddress')}>
                    <Icons.Edit />
                </AddressButton>
                <AddressButton kind="ghost" data-tooltip={t('removeAddress')}>
                    <Icons.Trash />
                </AddressButton>
            </div>
            {streetRow && (
                <span>
                    {streetRow}
                    <br />
                </span>
            )}
            {cityRow && (
                <span>
                    {cityRow}
                    <br />
                </span>
            )}
            {countryRow && (
                <span>
                    {countryRow}
                    <br />
                </span>
            )}
        </Container>
    );
};

export default AddressInput;
