import { useMutation, useQuery } from '@tanstack/react-query';
import { checkAuth, getUser, login, getAuth0Config } from '@apis/user';

export function useCheckAuth(options: { enabled?: boolean } = {}) {
    const { enabled = true } = options;
    return useQuery({
        queryKey: ['user', 'checkAuth'],
        queryFn: async () => {
            return checkAuth();
        },
        enabled,
    });
}

export function useLogin() {
    return useMutation({
        mutationFn: async (data: { username: string; password: string }) => {
            const { username, password } = data;

            return login({ username, password });
        },
    });
}

export function useGetUser(options: { enabled?: boolean } = {}) {
    const { enabled = true } = options;
    return useQuery({
        queryKey: ['user', 'info'],
        queryFn: async () => {
            return getUser();
        },
        enabled,
    });
}

export function useGetAuth0Config() {
    return useQuery({
        queryKey: ['user', 'auth0Config'],
        queryFn: async () => {
            return getAuth0Config();
        },
    });
}
