import {
    GetTxtConstantsQuery,
    TxtConstant,
    TxtConstantsFilter,
} from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetTxtConstants from './queries/GetTxtConstants.graphql';
import { useTxtConstants as txtConstantsStore } from '@stores';

/**
 * Get the txt constants.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The txt constants.
 */
export const getTxtConstants = async (
    filter?: TxtConstantsFilter,
    limit?: number,
): Promise<TxtConstant[]> => {
    const { txtConstants, getByGroupName } = txtConstantsStore.getState();
    if (filter?.groupname?.eq && txtConstants.length > 0) {
        const txtconstants = getByGroupName(filter.groupname.eq);
        if (txtconstants.length > 0) {
            return txtconstants;
        }
    }
    try {
        const queryVariables = {
            filter: filter,
            limit: limit,
        };
        const response = await sendGraphQLRequest<GetTxtConstantsQuery>(
            GetTxtConstants,
            queryVariables,
        );

        if (!response.data.getTxtConstants) {
            throw Error('No data returned');
        }

        return response.data.getTxtConstants.txtConstants?.filter(
            Boolean,
        ) as TxtConstant[];
    } catch (error) {
        throw Error('Failed to get txt constants: ' + error);
    }
};
