import { create } from 'zustand';
import { getProduct } from '@apis/user';
import { ProductType } from '@generated';

interface ProductStore {
    product: ProductType | undefined;
}

/**
 * ProductStore is a store that provides the product information to the application.
 */
export const useProductStore = create<ProductStore>((set) => {
    const initializeProduct = async () => {
        try {
            const product = await getProduct();
            set({ product });
        } catch (error) {
            console.error('Failed to fetch product:', error);
            set({ product: undefined });
        }
    };

    // Automatically fetch the product when the store is first accessed
    initializeProduct();

    return {
        product: undefined,
    };
});
