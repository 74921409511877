query getConduits($filter: ConduitsFilter) {
  getConduits(filter: $filter) {
     conduits{
      id
      identification
      length
      secondaryLength
      autoSecondaryLength
      measure {id, txt}
      mapper
      mappingDate
      surveyAmount
      locationAccuracy {id, txt}
      mappingMethod {id, txt}
      heightAccuracy {id, txt}
      safetyDistance {id, txt}
      width
      ductCount
      createdBy
      createdTs
      updatedBy
      updatedTs
      location
      categoryId
      remarks
      telecomArea {id, areaSize, perimeter}
      plan {id, name}
      conduitType {id, txt}
      profileStatus {id, txt}
      ducts {id}
      cables {id}
      surfaceType {id, txt}
      sourceSystem {id, txt}
      sourceKey
    }
  }
}
