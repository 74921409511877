import { useContext, useEffect, useRef, useState } from 'react';
import { Icons, MapController, ScaleAndDistant, styled } from '@keypro/2nd-xp';
import { useGetPlacementScales } from '@hooks/map';
import { t } from 'i18next';
import { useCenterMenu, useLeftMenu, useRightMenu } from '@stores';
import { AuthContext } from '@providers';
import { DateTime } from 'luxon';
import i18n from '@i18n';

export const PrintPreview = () => {
    const [controller, setController] = useState<MapController | undefined>();
    const { isMenuOpen: isLeftMenuOpen } = useLeftMenu();
    const { isMenuOpen: isRightMenuOpen, menuContentId: rightMenuContentId } =
        useRightMenu();
    const { setMenuContent: setCenterMenuContent } = useCenterMenu();
    const { user } = useContext(AuthContext);

    const [clipPath, setClipPath] = useState('');
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const printPreviewRef = useRef<HTMLDivElement>(null);
    const maskRef = useRef<HTMLDivElement>(null);

    const { data: placementScales } = useGetPlacementScales();

    useEffect(() => {
        // If the right menu is open e.g. from opening a FormBuilder during
        // print preview process, close the print preview to avoid jankiness
        if (isRightMenuOpen && rightMenuContentId !== t('print')) {
            setCenterMenuContent(t('print'), <></>);
        }
    }, [controller, isRightMenuOpen, rightMenuContentId, setCenterMenuContent]);

    useEffect(() => {
        if (placementScales) {
            setController(
                new MapController({
                    placementScales: placementScales,
                }),
            );
        }
    }, [placementScales]);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [windowSize, isLeftMenuOpen]);

    useEffect(() => {
        let observerRefValue = null;
        const compass = document.getElementsByClassName(
            'ol-rotate-reset',
        )[0] as HTMLElement;

        const updateCompassPosition = () => {
            if (!compass || !printPreviewRef.current) return;

            const modalRect = printPreviewRef.current.getBoundingClientRect();

            compass.style.position = 'absolute';
            compass.style.top = `${modalRect.top + 28}px`;
            compass.style.left = `${modalRect.right - 90}px`;
            compass.style.width = '50px';
        };

        const resizeObserver = new ResizeObserver(() => {
            updateCompassPosition();
            updateDarkMask();
        });

        if (printPreviewRef.current) {
            resizeObserver.observe(printPreviewRef.current);
            observerRefValue = printPreviewRef.current;
        }

        return () => {
            if (compass) {
                compass.style.position = '';
                compass.style.top = '';
                compass.style.left = '';
                compass.style.width = '';
            }
            if (observerRefValue) {
                resizeObserver.disconnect();
            }
        };
    });

    const updateDarkMask = () => {
        if (printPreviewRef.current && maskRef.current) {
            const maskRect = maskRef.current.getBoundingClientRect();
            const modalRect = printPreviewRef.current.getBoundingClientRect();

            let top = ((modalRect.top - maskRect.top) / maskRect.height) * 100;
            let left =
                ((modalRect.left - maskRect.left) / maskRect.width) * 100;
            let right =
                ((modalRect.right - maskRect.left) / maskRect.width) * 100;
            const bottom =
                ((modalRect.bottom - maskRect.top) / maskRect.height) * 100;

            // Cutting out round header and 1px each side to smooth the look
            const radiusX = (8 / maskRect.width) * 100;
            const radiusY = (8 / maskRect.height) * 100;
            const onePxX = (1 / maskRect.width) * 100;
            const onePxY = (1 / maskRect.height) * 100;

            top += onePxY;
            left += onePxX;
            right -= onePxX;

            const clip = `polygon(
            -2% -1%, 110% -1%, 110% 110%, -1% 110%, -1% -1%, 
            ${left + radiusX}% ${top}%, ${left}% ${top + radiusY}%, 
            ${left}% ${bottom}%, ${right}% ${bottom}%, 
            ${right}% ${top + radiusY}%, ${right - radiusX}% ${top}%, 
            ${left + radiusX}% ${top}%
        )`;

            setClipPath(clip);
        }
    };

    const todayString = () => {
        const dateFormat: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        };
        const locale = i18n.language;
        const today = new Date();
        const todayString = DateTime.fromISO(today.toISOString(), {
            locale: locale,
        }).toLocaleString(dateFormat);

        return todayString;
    };

    // Prevent rendering if still loading or if controller is not set
    if (controller === undefined) {
        return null;
    }

    return (
        <StyedContainer data-testid="printPreview-container">
            <StyledRelativeContainer ref={printPreviewRef}>
                <StyledPrintPreviewHeader data-testid="printPreview-header">
                    {t('printPreview')}
                </StyledPrintPreviewHeader>
                <StyledRelativeContainer>
                    <StyledTitleAndNote>
                        <StyledTitle>{t('public')}</StyledTitle>
                        <StyledNote>{t('note')}</StyledNote>
                    </StyledTitleAndNote>
                    <StyledLogo>
                        <Icons.KeyproLogo />
                    </StyledLogo>
                </StyledRelativeContainer>
                <StyledPrintPreviewBody>
                    <StyledEmptyFrame id="printPreview-printPaperSize" />
                </StyledPrintPreviewBody>
                <StyledPrintPreviewFooter>
                    <StyledScaleAndDistant />
                    <StyledAuthorAndDate>
                        <StyledDate>{todayString()}</StyledDate>
                        <div
                            id="printPreview-userEmail"
                            data-testid="printPreview-userEmail"
                        >
                            {user?.email ?? ''}
                        </div>
                    </StyledAuthorAndDate>
                </StyledPrintPreviewFooter>
            </StyledRelativeContainer>
            <StyledDarkMask style={{ clipPath: clipPath }} ref={maskRef} />
        </StyedContainer>
    );
};

const StyledDarkMask = styled.div`
    position: absolute;
    background-color: ${(props) => props.theme.colors.neutral['10']};
    opacity: 80%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    pointer-events: none;
`;

const StyledRelativeContainer = styled.div`
    position: relative;
`;

const StyledEmptyFrame = styled.div`
    height: 620px;
    width: 877px;
`;

const StyedContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    pointer-events: none;
`;

const StyledPrintPreviewHeader = styled.div`
    background-color: ${(props) => props.theme.colors.neutral['10']};
    color: ${(props) => props.theme.colors.neutral['90']};
    ${(props) => props.theme.fonts['14px Medium']};
    border-radius: 8px 8px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    pointer-events: auto;
`;

const StyledPrintPreviewBody = styled.div`
    padding: 0 24px 8px 24px;
    background-color: ${(props) => props.theme.colors.neutral['100']};
    opacity: 80%;
    clip-path: polygon(
        -1% -1%,
        0% 110%,
        calc(0% + 30px) 100%,
        calc(0% + 30px) 0%,
        calc(100% - 30px) 0%,
        calc(100% - 30px) 100%,
        3% 100%,
        3% 110%,
        110% 100%,
        100% -1%
    );
`;

const StyledTitleAndNote = styled.div`
    display: flex;
    gap: 16px;
    color: ${(props) => props.theme.colors.neutral['10']};
    background-color: ${(props) => props.theme.colors.neutral['100']};
    opacity: 80%;
    padding: 8px 24px;
`;

const StyledTitle = styled.p`
    ${(props) => props.theme.fonts['14px Bold']};
    margin: 0;
`;

const StyledNote = styled.p`
    ${(props) => props.theme.fonts['14px Regular']};
    margin: 0;
`;

const StyledLogo = styled.span`
    z-index: 1;
    position: absolute;
    padding-top: 8px;
    padding-left: 35px;
    & > svg {
        width: 80px;
        height: 18px;
    }
`;

const StyledScaleAndDistant = styled(ScaleAndDistant)`
    z-index: 1;
    height: auto !important;
    position: absolute;
    inset: auto 30px 30px auto;
`;

const StyledPrintPreviewFooter = styled.div`
    background-color: ${(props) => props.theme.colors.neutral['100']};
    opacity: 80%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
`;

const StyledAuthorAndDate = styled.div`
    ${(props) => props.theme.fonts['12px Regular']};
    color: ${(props) => props.theme.colors.neutral['10']};
`;

const StyledDate = styled.div`
    text-align: right;
`;
