import { PrintResponse, PrintArgs, QuickPrintQuery } from '@generated';
import { sendGraphQLRequest } from '@utils';
import QuickPrint from './queries/QuickPrint.graphql';

/**
 * Quick print.
 * @returns {Promise<PrintResponse>} The print response.
 * @throws {Error} If the request fails.
 */
export const quickPrint = async (args: PrintArgs): Promise<PrintResponse> => {
    try {
        const response = await sendGraphQLRequest<QuickPrintQuery>(QuickPrint, {
            input: args,
        });

        if (!response.data.quickPrint) {
            throw Error('No data returned');
        }

        return response.data.quickPrint;
    } catch (error) {
        throw Error('Failed to quick print: ' + error);
    }
};
