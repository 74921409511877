import { Dispatch, RefObject, SetStateAction } from 'react';
import { SelectOptionItem } from '@keypro/2nd-xp';
import Polygon from 'ol/geom/Polygon';
import { Coordinate } from 'ol/coordinate';

/**
 * Adds a click event listener to the document that deactivates the component
 * when clicking outside of it. Should be used in a useEffect hook.
 * @param ref The reference to the component
 * @param setActive The setter for the active state
 * @param isActive The active state
 * @returns The cleanup function for useEffect
 */
export const handleOutsideClick = (
    ref: RefObject<HTMLElement>,
    setActive: Dispatch<SetStateAction<boolean>>,
    isActive: boolean,
) => {
    const onClick = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setActive(false);
        }
    };

    if (isActive) {
        document.addEventListener('click', onClick);
    } else {
        document.removeEventListener('click', onClick);
    }

    return () => {
        document.removeEventListener('click', onClick);
    };
};

/**
 * Returns the icon for the given layer name.
 * @param layerName Name of the layer
 */
export const infoToolsLayers = {
    freearea: 'freearea',
    address: 'keygwt_address',
    telecompremise: 'prem',
    cable: 'cable',
    splice: 'splice',
    freeline: 'freeline',
    manhole: 'manhole',
    conduit: 'conduit',
};

// Predefined object types for search filter dropdown.
// NOTE: These should be made configurable in the future.
export const objectTypes: SelectOptionItem[] = [
    {
        value: 'freeareasearch',
        label: 'Areas',
    },
    {
        value: 'addresssearch',
        label: 'Addresses',
    },
    {
        value: 'telecompremisesearch',
        label: 'Premises',
    },
    {
        value: 'cablesearch',
        label: 'Cables',
    },
    {
        value: 'splicesearch',
        label: 'Splices',
    },
    {
        value: 'freelinesearch',
        label: 'Lines',
    },
    {
        value: 'manholesearch',
        label: 'Manholes',
    },
    {
        value: 'conduitsearch',
        label: 'Conduits',
    },
];

/**
 * Creates a rectangular polygon around the given point.
 * @param center Center of the polygon.
 * @param size Desired size of the polygon.
 * @returns Polygon around the given point.
 */
export const pointToPolygon = (center: Coordinate, size: number): Polygon => {
    const [x, y] = center;
    const offset = size / 2;
    const coords = [
        [x - offset, y - offset],
        [x + offset, y - offset],
        [x + offset, y + offset],
        [x - offset, y + offset],
        [x - offset, y - offset],
    ];

    return new Polygon([coords]);
};
