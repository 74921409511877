import { styled } from '@keypro/2nd-xp';
import { HTMLAttributes } from 'react';

const Anchor = styled.a`
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
    position: relative;
`;

/**
 * Props for Link component.
 */
export interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
    /** Target URL */
    url: string;
    /** Text to display */
    text: string;
}

/**
 * Link component for forms.
 */
const Link = (props: LinkProps): JSX.Element => {
    const { url, text, ...rest } = props;

    return (
        <Anchor href={url} target="_blank" {...rest}>
            {encodeURIComponent(text)}
        </Anchor>
    );
};

export default Link;
