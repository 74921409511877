import { getCables } from '@apis/keycom';
import { FormConfig } from '../types';
import { commonGroup, planGroup, telecomAreaGroup } from '../groups';
import { Icons } from '@keypro/2nd-xp';

const config: FormConfig = {
    gqlType: 'Cable',
    model: 'cable',
    icon: Icons.Cable,
    groups: [
        {
            name: 'objectData',
            fields: [
                {
                    name: 'owner',
                    filter: {
                        groupname: { eq: 'CABLE_OWNER' },
                    },
                },
                {
                    name: 'renter',
                    filter: {
                        groupname: { eq: 'CABLE_OWNER' },
                    },
                },
                'identification',
                {
                    name: 'state',
                    filter: {
                        groupname: { eq: 'MANHOLE_STATE' },
                    },
                },
                'length',
                'extraLength',
                'totalLength',
                'installYear',
                'startReading',
                'endReading',
                {
                    name: 'measure',
                    filter: {
                        groupname: { eq: 'CABLE_MEASURE' },
                    },
                },
                {
                    name: 'installationType',
                    filter: {
                        groupname: { eq: 'CABLE_TYPE' },
                    },
                },
                {
                    name: 'technology',
                    filter: {
                        groupname: { eq: 'CABLE_TECHNOLOGY' },
                    },
                },
                {
                    name: 'role',
                    filter: {
                        groupname: { eq: 'CABLE_ROLE' },
                    },
                },
                'batch',
                'importance',
                'cableId',
                {
                    name: 'remarks',
                    component: 'textarea',
                },
            ],
        },
        telecomAreaGroup,
        planGroup,
        {
            name: 'common',
            fields: [
                ...commonGroup.fields,
                {
                    name: 'safetyDistance',
                    filter: {
                        groupname: { eq: 'SAFETY_DISTANCE' },
                    },
                },
            ],
        },
    ],
    functions: {
        get: getCables,
    },
    queryCollection: 'cables',
};

export default config;
