import { create } from 'zustand';
import { getTxtConstants } from '@apis/keycore';
import { TxtConstant } from '@generated';

interface TxtConstantState {
    txtConstants: TxtConstant[];
    isLoading: boolean;
    fetchTxtConstants: (limit?: number) => Promise<void>;
    getByGroupName: (groupname: string) => TxtConstant[];
}

export const useTxtConstants = create<TxtConstantState>((set, get) => ({
    txtConstants: [],
    isLoading: false,
    error: null,
    fetchTxtConstants: async () => {
        set({ isLoading: true });
        try {
            const txtConstants = await getTxtConstants(undefined, 20000);
            set({ txtConstants, isLoading: false });
        } catch (error) {
            set({ isLoading: false });
            throw error;
        }
    },
    getByGroupName: (groupname: string) => {
        const { txtConstants } = get();
        return txtConstants.filter(
            (txtConstant) => txtConstant.groupname === groupname,
        );
    },
}));
