import {
    TreeView,
    MapContext,
    TreeNodeType,
    LayerSettingsButton,
    styled,
} from '@keypro/2nd-xp';
import { t } from 'i18next';
import React, {
    useEffect,
    useState,
    useRef,
    useContext,
    useCallback,
} from 'react';
import { constructTreeViewData, hasChildren } from './layerHelpers';
import { LayerSettingsPopper } from './LayerSettingsPopper';
import NoFavouriteLayersImage from '@assets/images/no-favourite-layers.webp';

export interface ExtendedTreeNode extends TreeNodeType {
    label?: string;
    children?: ExtendedTreeNode[];
}

const NoFavouritesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 70%;
`;

const NoFavouriteText = styled.span`
    color: ${({ theme }) => theme.colors.neutral['100']};
    ${({ theme }) => theme.fonts['16px Bold']};
    text-align: center;
`;

const NoFavouritesDescription = styled.span`
    color: ${({ theme }) => theme.colors.neutral['80']};
    ${({ theme }) => theme.fonts['14px Regular']};
    text-align: center;
`;

const NoFavouriteLayers = (): JSX.Element => {
    return (
        <NoFavouritesWrapper
            className="no-favourite-layers"
            data-testid="no-favourite-layers"
            style={{ display: 'flex', flexDirection: 'column' }}
        >
            <img src={NoFavouriteLayersImage} alt="No favourite layers" />
            <NoFavouriteText>{t('noFavouriteLayers')}</NoFavouriteText>
            <NoFavouritesDescription>
                {t('favouriteLayersDescription')}
            </NoFavouritesDescription>
        </NoFavouritesWrapper>
    );
};

/**
 * LayerTreeView component, displays a tree view of OpenLayers Layers and allows the user to enable/disable layers
 * @returns LayerTreeView component
 */
const LayerTreeView = ({
    search,
    display,
}: {
    search: string;
    display: 'layers' | 'favourites';
}) => {
    const controller = useContext(MapContext)!;
    const [layerTreeData, setLayerTreeData] = useState<ExtendedTreeNode[]>(
        constructTreeViewData(controller, display === 'favourites'),
    );
    const [selected, setSelected] = useState<Set<string>>(
        new Set(controller.layers.getActiveLayerNames()),
    );
    const prevSelectedRef = useRef<Set<string>>(new Set());
    const [activeNodeId, setActiveNodeId] = useState<string | null>(null);
    const [favouriteLayersChanged, setFavouriteLayersChanged] =
        useState<boolean>(false);

    const [referenceElement, setReferenceElement] =
        useState<HTMLElement | null>(null);

    // Close the layer settings popper
    const handleClose = useCallback(() => {
        setActiveNodeId(null);
    }, [setActiveNodeId]);

    const handleLayerSettingsClick = useCallback(
        (layerId: string, event: React.MouseEvent<HTMLButtonElement>) => {
            if (activeNodeId === layerId) {
                handleClose();
            } else {
                event.stopPropagation();
                setActiveNodeId(layerId);
                setReferenceElement(event.currentTarget);
            }
        },
        [activeNodeId, handleClose, setActiveNodeId],
    );

    const renderLayerSettingsButton = useCallback(
        (layerId: string) => (
            <LayerSettingsButton
                isActive={activeNodeId === layerId}
                id={`button-${layerId}`}
                data-tooltip={t('layerSettings')}
                style={{ marginLeft: 'auto' }}
                onClick={(event) => {
                    event.stopPropagation();
                    handleLayerSettingsClick(layerId, event);
                }}
            />
        ),
        [activeNodeId, handleLayerSettingsClick],
    );

    useEffect(() => {
        if (display === 'favourites' && favouriteLayersChanged) {
            setActiveNodeId(null);
            setFavouriteLayersChanged(false);
            setLayerTreeData(
                constructTreeViewData(controller, display === 'favourites'),
            );
        }
    }, [controller, display, favouriteLayersChanged]);

    // Enable/Disable layers based on selected state
    useEffect(() => {
        const prevSelected = prevSelectedRef.current;

        const added = Array.from(selected).filter(
            (id) => !prevSelected.has(id) && !hasChildren(id, layerTreeData),
        );
        const removed = Array.from(prevSelected).filter(
            (id) => !selected.has(id) && !hasChildren(id, layerTreeData),
        );

        try {
            added.forEach((id) => {
                controller.layers.setLayerVisibility(id, true);
            });
            removed.forEach((id) => {
                controller.layers.setLayerVisibility(id, false);
            });
        } catch (error) {
            console.error('Error setting layer visibility', error);
        }

        prevSelectedRef.current = selected;
    }, [selected, controller, layerTreeData]);

    useEffect(() => {
        setActiveNodeId(null);
    }, [display]);

    return (
        <>
            {display === 'favourites' && layerTreeData.length === 0 && (
                <NoFavouriteLayers />
            )}
            <TreeView
                data={layerTreeData}
                selected={selected}
                setSelected={setSelected}
                search={search}
                hoverComponent={renderLayerSettingsButton}
            />
            <LayerSettingsPopper
                activeNodeId={activeNodeId}
                handleClose={handleClose}
                referenceElement={referenceElement}
                setFavouriteLayersChanged={setFavouriteLayersChanged}
            />
        </>
    );
};

export default LayerTreeView;
