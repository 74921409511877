import { GetInfoToolsQuery, SearchArea } from '@generated';
import GetInfoTools from './queries/GetInfoTools.graphql';
import { sendGraphQLRequest } from '@utils';

/**
 * Gets the info tools.
 * @returns {Promise<InfoTool[]>} The info tools.
 * @throws {Error} If the request fails.
 */
export const fetchInfoTools = async (options: {
    layers: string[];
    searchArea: SearchArea;
}) => {
    const result = await sendGraphQLRequest<GetInfoToolsQuery>(GetInfoTools, {
        filter: {
            layers: options.layers,
            searchArea: options.searchArea,
        },
    });

    if (!result.data.getInfoTools) {
        throw new Error('Failed to get info tools');
    }

    return {
        total: result.data.getInfoTools.total,
        results: result.data.getInfoTools.results,
    };
};
