import { getSplices } from '@apis/keycom';
import { FormConfig, FormGroup } from '@form-configs';

const createConfig = (groups: FormGroup[] = []): FormConfig => ({
    gqlType: 'SpliceSearch',
    model: 'splicesearch',
    groups,
    functions: {
        get: getSplices,
    },
    queryCollection: 'splices',
});

const config = createConfig();

export default config;
