import { GetManholesQuery, Manhole } from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetManholes from './queries/GetManholes.graphql';
import { GraphQLFilter, isFilterEmpty } from '../utils';

/**
 * Get the manhole.
 * @param id The manhole ID.
 * @returns The manhole.
 */
export const getManhole = async (id: string) => {
    return await getManholes({ id: { eq: id } });
};

/**
 * Gets the manholes.
 * @param filter The GraphQL filter to apply to the query.
 * @returns {Promise<Manhole[]>} The manholes.
 * @throws {Error} If the request fails.
 */
export const getManholes = async (
    filter: GraphQLFilter,
): Promise<Manhole[]> => {
    try {
        const payload = !isFilterEmpty(filter)
            ? {
                  filter: filter,
              }
            : {
                  filter: null,
                  limit: 1,
              };
        const response = await sendGraphQLRequest<GetManholesQuery>(
            GetManholes,
            payload,
        );

        if (!response.data.getManholes) {
            throw Error('No data returned');
        }

        return response.data.getManholes.manholes.filter(Boolean) as Manhole[];
    } catch (error) {
        throw Error('Failed to get manholes: ' + error);
    }
};
