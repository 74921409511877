query getCables($filter: CableFilter) {
  getCables(filter: $filter) {
     cables{
      id
      installYear
      length
      extraLength
      totalLength
      falseCable
      rentPaid
      identification
      remarks
      mapper
      historyDate
      startReading
      endReading
      location
      createdBy
      createdTs
      updatedBy
      updatedTs
      locationAccuracy {id, txt}
      mappingMethod {id, txt}
      heightAccuracy {id, txt}
      safetyDistance {id, txt}
      telecomArea {id, areaSize, perimeter}
      state {id, txt}
      owner {id, txt}
      renter {id, txt}
      plan {id, name}
      cableType {id, type}
      installationType {id, txt}
      technology {id, txt}
      measure {id, txt}
      role {id, txt}
      batch
      cableId
      importance
      supercable {id, name}
      supercableOrderno
      totalCapacity
      connectedCapacity
      usedCapacity
      freeCapacity
      connections {
        cableDirection
        premises {id, name}
        manhole {id, identification}
        splice {id, identification}
      }
    }
  }
}
