import { useSearch } from '@hooks/keycore/search';
import { InfoObject } from '@components';
import { handleOutsideClick, pointToPolygon } from '@components/utils';
import { Maybe, SearchResult, SearchArea } from '@generated';
import {
    Button,
    Icons,
    Input,
    LoadingMask,
    MapContext,
    PositionOverlay,
    QuickSearchResultCard,
    QuickSearchResultContainer,
    styled,
    Coordinates,
    MapController,
} from '@keypro/2nd-xp';
import {
    useLeftMenu,
    useRecentObjects,
    useRightMenu,
    useProductStore,
} from '@stores';
import { t } from 'i18next';
import {
    ChangeEvent,
    HTMLAttributes,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { FullSearch } from './FullSearch';
import {
    getLabel,
    getModelIcon,
    getTranslatedTitle,
    getTypeByModel,
} from '@form-configs';
import { useGetInfoTools } from '@hooks/map';
import { getDistance } from 'ol/sphere';

/**
 * Delay in milliseconds before search is performed after user has stopped typing.
 * This delay helps to reduce the number of search requests when user is typing.
 */
const SEARCH_DELAY = 250;

/**
 * Diameter of the search area around the given coordinates in meters.
 */
const COORDINATE_SEARCH_DIAMETER = 100;

/**
 * Initial number of objects shown below the search field.
 */
const INITIAL_OBJECT_COUNT = 10;

const SearchInput = styled(Input)`
    background-color: ${(props) => props.theme.colors.neutral[20]};
    border: 0;
    width: 332px;

    &:focus,
    &:focus-within {
        box-shadow: none;
    }

    &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

const SearchContainer = styled.div`
    position: relative;
`;

const QuickSearchResults = styled.div`
    position: absolute;
    top: 100%;

    z-index: 100;
    width: 100%;

    & > div,
    & > div > div {
        border-radius: 0 0 8px 8px;
        background-color: ${(props) => props.theme.colors.neutral[20]};
    }

    & > div {
        border-top: 1px solid ${(props) => props.theme.colors.neutral[30]};
    }
`;

const ShowMoreResults = styled.div`
    border-top: 1px solid ${(props) => props.theme.colors.neutral[30]};

    display: flex;
    justify-content: center;
    padding: 12px 12px 8px 12px;
    margin-left: 8px;

    button {
        border: 0;
        background-color: transparent;
        color: ${(props) => props.theme.colors.accents.blue[10]};
        ${(props) => props.theme.fonts['14px Regular']};
        cursor: pointer;
    }
`;

const SearchButton = styled(Button)`
    width: 24px;
    height: 24px;

    svg {
        width: 16px;
        height: 16px;

        path {
            fill: ${(props) => props.theme.colors.neutral[80]};
            stroke: ${(props) => props.theme.colors.neutral[80]};
        }
    }
`;

const CloseButton = styled(Button)`
    background-color: ${(props) => props.theme.colors.neutral[50]};
    width: 20px;
    height: 20px;
    border-radius: 50%;

    svg {
        width: 9px;
        height: 9px;

        path {
            stroke-width: 4px;
            color: ${(props) => props.theme.colors.neutral[90]};
        }
    }

    &:hover {
        background-color: ${(props) =>
            props.theme.colors.neutral[60]} !important;
    }
`;

const EndButtons = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const placeHolderText = (product: string) => {
    switch (product) {
        case 'keycom':
            return t('searchKeycom');
        case 'keyaqua':
            return t('searchKeyaqua');
        default:
            return '';
    }
};

type ResultDistance = [number | null, SearchResult];

/**
 * Sorts search results by distance to the given coordinates.
 * @param results Search results.
 * @param coordinates Coordinates to sort by.
 * @returns Sorted search results.
 */
const sortResultsByDistance = (
    mapController: MapController,
    results: SearchResult[],
    coordinates: number[],
): SearchResult[] => {
    const resultsByDistance: ResultDistance[] = results.map((result) => {
        if (!result.location) {
            return [null, result];
        }

        const geometry = mapController.wktToGeometry(result.location);
        const point1 = mapController.convertToWSG84(coordinates);
        const point2 = mapController.convertToWSG84(
            geometry.getClosestPoint(coordinates),
        );

        return [getDistance(point1, point2), result];
    });

    resultsByDistance.sort((a: ResultDistance, b: ResultDistance) => {
        if (a[0] == null || b[0] == null) {
            return 0;
        }

        return a[0] - b[0];
    });

    return resultsByDistance
        .filter(
            (result) =>
                result[0] != null && result[0] <= COORDINATE_SEARCH_DIAMETER,
        )
        .map((result) => result[1]);
};

/**
 * Combined search field for both quick and full search.
 */
const SearchField = (props: HTMLAttributes<HTMLDivElement>) => {
    const [displayValue, setDisplayValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [timeoutId, setTimeoutId] = useState<number | null>(null);
    const [active, setActive] = useState(false);
    const mapController = useContext(MapContext)!;
    const { setMenuContent: setRightMenuContent } = useRightMenu();
    const [highlightPosition, setHighlightPosition] = useState<number[]>([
        0, 0,
    ]);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const {
        setMenuContent: setLeftMenuContent,
        isMenuOpen: resultsOpen,
        toggleMenu: toggleLeftMenu,
    } = useLeftMenu();
    const { recentObjects } = useRecentObjects();
    const { product } = useProductStore();

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDisplayValue(event.target.value);

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = window.setTimeout(() => {
            setSearchValue(event.target.value);
        }, SEARCH_DELAY);

        setTimeoutId(newTimeoutId);
    };

    const onFocus = () => {
        setActive(true);
    };

    const showFullSearch = () => {
        setActive(false);
        // Show full search
        setLeftMenuContent(
            `FullSearch-${searchValue}`,
            <FullSearch
                searchResults={results}
                searchTerm={searchValue}
                setQuickSearchIsHighlighted={setIsHighlighted}
            />,
        );
    };

    const showCoordinateSearchResults = () => {
        setActive(false);
        setLeftMenuContent(
            `FullSearch-${searchValue}`,
            <FullSearch
                searchResults={sortedCoordResults}
                limitAdvancedSearch={true}
            />,
        );
    };

    const showRecentObjects = () => {
        setActive(false);
        setLeftMenuContent(
            'FullSearch',
            <FullSearch
                searchResults={recentObjects}
                limitAdvancedSearch={true}
            />,
        );
    };

    // Match search value to comma separated coordinates
    const coordinateMatches = !!/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/.exec(
        searchValue,
    );

    let isCoordinateSearch = false;
    let coordinates: number[] = [];
    let searchArea: SearchArea | undefined;
    let layers: string[] = [];

    if (coordinateMatches) {
        isCoordinateSearch = true;
        coordinates = searchValue.split(',').map((coord) => parseFloat(coord));
        layers = mapController.layers.getActiveLayerNames();

        const polygon = pointToPolygon(
            coordinates as Coordinates,
            COORDINATE_SEARCH_DIAMETER,
        );

        searchArea = {
            type: 'Feature',
            properties: null,
            geometry: {
                type: 'Polygon',
                coordinates: polygon.getCoordinates(),
            },
        } as SearchArea;
    }

    const { data: results, isFetching: searching } = useSearch(searchValue, {
        enabled: !isCoordinateSearch,
    });

    const { data: coordResults, isFetching: coordSearching } = useGetInfoTools(
        {
            layers: layers,
            searchArea: searchArea,
        },
        {
            enabled: isCoordinateSearch,
        },
    );

    let sortedCoordResults: SearchResult[] = [];

    if (isCoordinateSearch && coordResults) {
        sortedCoordResults = sortResultsByDistance(
            mapController,
            coordResults.results.map((result) => ({
                id: result.pk ? parseInt(result.pk) : null,
                model_name: result.model,
                identification: result.identification,
                location: result.location,
            })),
            coordinates,
        );
    }

    const resultsForQuickSearch = useMemo(() => {
        if (searchValue && results) {
            return results.slice(0, INITIAL_OBJECT_COUNT);
        }

        return [];
    }, [searchValue, results]);

    const endButtons: JSX.Element[] = [
        <SearchButton
            key="doSearch"
            kind="ghost"
            onClick={showFullSearch}
            data-tooltip={t('openFullSearch')}
            data-testid="open-full-search-button"
        >
            <Icons.Search />
        </SearchButton>,
    ];

    const closeSearch = () => {
        // Reactivate search field on the next animation frame after closing results
        requestAnimationFrame(() =>
            ref.current?.querySelector('input')?.focus(),
        );

        if (resultsOpen) {
            toggleLeftMenu();
        }

        setActive(false);
        setDisplayValue('');
        setSearchValue('');
        setIsHighlighted(false);
    };

    if (searchValue || resultsOpen) {
        endButtons.push(
            <CloseButton
                key="clearSearch"
                kind="ghost"
                data-tooltip={t('closeSearch')}
                onClick={closeSearch}
                data-testid="close-search-button"
            >
                <Icons.Cross2 />
            </CloseButton>,
        );
    }

    // Hide results when clicking outside of search field or results component
    useEffect(() => {
        return handleOutsideClick(ref, setActive, active);
    }, [ref, active]);

    /**
     * Shows the object in the right menu.
     * @param model Model of the object.
     * @param id ID of the object.
     */
    const showObject = (model: string, id: number) => {
        setRightMenuContent(
            `QuickSearchObject-${model}-${id}`,
            <InfoObject model={model} id={id.toString()} />,
        );
    };

    /**
     * Locates and highlights the object on the map.
     * @param location Location of the object.
     */
    const locate = (location: Maybe<string> | undefined) => {
        if (!location) return;

        const geom = mapController.wktToGeometry(location);

        if (geom) {
            const extent = geom.getExtent();
            const center = [
                (extent[0] + extent[2]) / 2,
                (extent[1] + extent[3]) / 2,
            ];
            mapController.pan(center);
            setIsHighlighted(true);
            setHighlightPosition(center);
        }
    };

    // Clear highlight when search value changes
    useEffect(() => {
        setIsHighlighted(false);
    }, [searchValue]);

    /**
     * Creates a search result card for the given search result.
     * @param result Search result.
     * @param index Index of the search result.
     * @returns Search result card.
     */
    const createSearchResultCard = (result: SearchResult, index: number) => {
        let name = result.identification ?? '';
        let info = t('unknownObjectType');

        if (result.model_name) {
            const gqlType = getTypeByModel(result.model_name);
            name = getLabel(gqlType, result);
            info = getTranslatedTitle(gqlType);
        }

        return (
            <QuickSearchResultCard
                key={result.id}
                name={name}
                info={info}
                objectIcon={getModelIcon(result.model_name)}
                openObjectIcon={<Icons.Open data-tooltip={t('openForm')} />}
                locateIcon={
                    <Icons.Locate2 data-tooltip={t('locateAndHighlight')} />
                }
                searchTerm={searchValue}
                onLocateAction={() => locate(result.location)}
                onOpenObjectAction={() =>
                    showObject(result.model_name!, result.id!)
                }
                onClickAction={() => showObject(result.model_name!, result.id!)}
                data-testid={`search-result-${index}`}
            />
        );
    };

    /**
     * Get content for quick search results.
     * @returns Quick search content.
     */
    const getQuickSearchContent = () => {
        if (resultsForQuickSearch?.length) {
            return (
                <QuickSearchResultContainer
                    title={t('keycomSuggestions')}
                    data-testid="search-results"
                >
                    {resultsForQuickSearch.map(createSearchResultCard)}
                    <ShowMoreResults>
                        <button
                            onClick={showFullSearch}
                            data-testid="show-more-results"
                        >
                            {t('showMoreResults')}
                        </button>
                    </ShowMoreResults>
                </QuickSearchResultContainer>
            );
        } else {
            return (
                <QuickSearchResultContainer
                    title={t('noSearchResults')}
                ></QuickSearchResultContainer>
            );
        }
    };

    /**
     * Get content for coordinate search results.
     * @returns Coordinate search content.
     */
    const getCoordinateSearchContent = () => {
        if (sortedCoordResults.length) {
            return (
                <QuickSearchResultContainer
                    title={t('nearbyObjects')}
                    data-testid="coord-search-results"
                >
                    {sortedCoordResults
                        .slice(0, INITIAL_OBJECT_COUNT)
                        .map((result, index) =>
                            createSearchResultCard(result, index),
                        )}
                    <ShowMoreResults>
                        <button
                            onClick={showCoordinateSearchResults}
                            data-testid="show-more-coord-results"
                        >
                            {t('moreFromNearbyObjects')}
                        </button>
                    </ShowMoreResults>
                </QuickSearchResultContainer>
            );
        } else {
            return (
                <QuickSearchResultContainer
                    title={t('noNearbyObjects')}
                ></QuickSearchResultContainer>
            );
        }
    };

    /**
     * Get content for recently viewed objects.
     * @returns Recently viewed content.
     */
    const getRecentlyViewedContent = () => (
        <QuickSearchResultContainer
            title={t('recentlyViewed')}
            data-testid="recent-objects"
        >
            {recentObjects
                .slice(0, INITIAL_OBJECT_COUNT)
                .map(createSearchResultCard)}
            <ShowMoreResults>
                <button
                    onClick={showRecentObjects}
                    data-testid="show-more-history"
                >
                    {t('moreFromRecentHistory')}
                </button>
            </ShowMoreResults>
        </QuickSearchResultContainer>
    );

    let content: JSX.Element;

    if (searching || coordSearching) {
        content = (
            <QuickSearchResultContainer>
                <LoadingMask iconSize={24} />
            </QuickSearchResultContainer>
        );
    } else if (!isCoordinateSearch && searchValue) {
        content = getQuickSearchContent();
    } else if (isCoordinateSearch) {
        content = getCoordinateSearchContent();
    } else if (recentObjects.length) {
        content = getRecentlyViewedContent();
    } else {
        content = (
            <QuickSearchResultContainer
                title={t('noRecentObjects')}
            ></QuickSearchResultContainer>
        );
    }

    return (
        <SearchContainer ref={ref} {...props}>
            <PositionOverlay
                position={highlightPosition as Coordinates}
                positioning="bottom-center"
                style={{
                    display: isHighlighted ? 'block' : 'none',
                }}
            >
                <Icons.LocationMark style={{ width: 40, height: 40 }} />
            </PositionOverlay>
            <SearchInput
                inputProps={{
                    value: displayValue,
                    placeholder: placeHolderText(product ?? ''),
                    onChange: onChange,
                    onFocus: onFocus,
                    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                            showFullSearch();
                        }
                    },
                }}
                iconEnd={<EndButtons>{endButtons}</EndButtons>}
                className={active ? 'active' : ''}
            />
            {active && <QuickSearchResults>{content}</QuickSearchResults>}
        </SearchContainer>
    );
};

export default SearchField;
