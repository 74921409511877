import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import '@keypro/2nd-xp/dist/assets/fonts/Inter-VariableFont_opsz,wght.ttf';
import './global.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CombinedAuthProvider } from '@providers';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <CombinedAuthProvider>
                <App />
            </CombinedAuthProvider>
        </QueryClientProvider>
    </React.StrictMode>,
);
