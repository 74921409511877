import { getFreeLines } from '@apis/keycore';
import { FormConfig, FormGroup } from '@form-configs';

const createConfig = (groups: FormGroup[] = []): FormConfig => ({
    gqlType: 'FreeLineSearch',
    model: 'freelinesearch',
    groups,
    functions: {
        get: getFreeLines,
    },
    queryCollection: 'freeLines',
});

const config = createConfig();

export default config;
