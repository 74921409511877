import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useGetInfoTools } from '@hooks/map';
import {
    MapContext,
    ObjectPopup,
    ObjectPopupItem,
    PositionOverlay,
    Icons,
    styled,
    InfoToolSelectionEvent,
    InfoToolSelectionMode,
    ObjectDivider,
    NearbyObject,
    Coordinates,
} from '@keypro/2nd-xp';
import { InfoTool, SearchArea } from '@generated';
import { ToggleObjectController } from './ToggleObjectController';
import { useTranslation } from 'react-i18next';
import { useCenterMenu, useLeftMenu, useRightMenu } from '@stores';
import { InfoObject } from '@components/InfoObject';
import {
    getFormConfig,
    getModelIcon,
    getTranslatedTitle,
    getTypeByModel,
    isEmpty,
    isModelSupported,
} from '@form-configs';
import { MapBrowserEvent } from 'ol';
import { pointToPolygon } from '@components';

/**
 * The maximum number of results to display in a category before it can no longer be expanded.
 */
const EXPANDABLE_LIMIT = 20;

/**
 * The maximum number of results to display in a category before it's no longer expanded by default.
 */
const EXPANDED_BY_DEFAULT_LIMIT = 10;

/**
 * The time in milliseconds before hover info request is sent.
 */
const HOVER_TIME = 250;

/**
 * The maximum display scale to enable hovering functionality (in cm).
 */
const MAX_HOVER_DISPLAY_SCALE = 2000;

/**
 * Display scale based multiplier used for calculating the search area around the point.
 */
const POINT_DISPLAY_SCALE_MULTIPLIER = 0.005;

/**
 * Info tool result groups.
 */
interface InfoToolGroups {
    [key: string]: InfoToolGroup;
}

/**
 * Info tool result group.
 */
interface InfoToolGroup {
    isExpanded: boolean;
    allowExpand: boolean;
    results: InfoTool[];
}

/**
 * The ObjectOverlay component displays content on top of the map.
 * @returns The ObjectOverlay component
 */
export const ObjectOverlay = () => {
    const { setMenuContent } = useRightMenu();
    const { infoToolSelectionMode } = useCenterMenu();
    const { isMenuOpen: isLeftMenuOpen, detailedMenu } = useLeftMenu();
    const { t } = useTranslation();
    const controller = useContext(MapContext)!;

    const [position, setPosition] = useState<[number, number] | undefined>([
        0, 0,
    ]);
    const [offset, setOffset] = useState<number>(0);

    const [infoToolFilter, setInfoToolFilter] = useState<
        | {
              layers: string[];
              searchArea: SearchArea;
          }
        | undefined
    >();

    const infoTools = useGetInfoTools({
        layers: infoToolFilter?.layers,
        searchArea: infoToolFilter?.searchArea,
    });

    const headerResult = useMemo(() => {
        return infoTools.data?.results?.[0];
    }, [infoTools.data]);

    const isInMapView =
        !!headerResult &&
        infoToolSelectionMode === InfoToolSelectionMode.MAP_VIEW;

    const hoverTimeoutId = useRef<number>(0);

    useEffect(() => {
        const setPositionAndLocate = (input: string) => {
            if (!isInMapView) {
                const geom = controller.wktToGeometry(input);
                if (geom) {
                    const extent = geom.getExtent();
                    const center: [number, number] = [
                        (extent[0] + extent[2]) / 2,
                        (extent[1] + extent[3]) / 2,
                    ];

                    setPosition(center);
                }
            }
        };

        headerResult?.location && setPositionAndLocate(headerResult.location);
    }, [headerResult, controller, isInMapView]);

    const { shouldDisplayInfoToolGroups, infoToolGroups } = useMemo(() => {
        const infoToolGroups: InfoToolGroups = {};

        const shouldDisplayInfoToolGroups =
            infoTools.data?.results && infoTools.data?.results?.length > 1;

        if (shouldDisplayInfoToolGroups && infoTools.data) {
            const results = infoTools.data.results.slice(1);

            const models = Array.from(
                new Set(results.map((result) => result.model)),
            );

            models.forEach((model) => {
                if (!model) return;

                const modelResults = results.filter(
                    (result) => result.model === model,
                );

                infoToolGroups[model] = {
                    allowExpand: modelResults.length <= EXPANDABLE_LIMIT,
                    isExpanded:
                        modelResults.length <= EXPANDED_BY_DEFAULT_LIMIT,
                    results: modelResults,
                };
            });
        }

        return {
            shouldDisplayInfoToolGroups,
            infoToolGroups,
        };
    }, [infoTools.data]);

    useEffect(() => {
        const handleInfoToolSelection = (event: InfoToolSelectionEvent) => {
            setInfoToolFilter({
                layers: event.layers,
                searchArea: event.feature,
            });
        };

        controller.on('infoToolSelection', handleInfoToolSelection);

        return () => {
            controller.off('infoToolSelection', handleInfoToolSelection);
        };
    }, [controller]);

    useEffect(() => {
        let isCursorOverMap = false;

        const setFilter = (layers: string[], coordinates: Coordinates) => {
            const size =
                controller.displayScale * POINT_DISPLAY_SCALE_MULTIPLIER;
            const polygon = pointToPolygon(coordinates, size);

            setInfoToolFilter({
                layers: layers,
                searchArea: {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: polygon.getCoordinates(),
                    },
                    properties: null,
                },
            });
            setPosition(coordinates);
        };

        const onMouseClick = (event: MapBrowserEvent<UIEvent>) => {
            const activeLayerNames = controller.layers.getActiveLayerNames();
            const coordinates: [number, number] = [
                event.coordinate[0],
                event.coordinate[1],
            ];

            setFilter(activeLayerNames, coordinates);
        };

        const onMouseMove = (event: MapBrowserEvent<UIEvent>) => {
            if (hoverTimeoutId.current) {
                clearTimeout(hoverTimeoutId.current);
            }

            if (
                isCursorOverMap &&
                controller.displayScale <= MAX_HOVER_DISPLAY_SCALE
            ) {
                hoverTimeoutId.current = window.setTimeout(() => {
                    const activeLayerNames =
                        controller.layers.getActiveLayerNames();
                    const coordinates: [number, number] = [
                        event.coordinate[0],
                        event.coordinate[1],
                    ];

                    setFilter(activeLayerNames, coordinates);
                }, HOVER_TIME);
            }
        };

        const onDocumentMouseOver = (event: MouseEvent) => {
            isCursorOverMap = event.target === document.querySelector('canvas');
        };

        controller.on('mouseClick', onMouseClick);
        controller.on('mouseMove', onMouseMove);
        document.addEventListener('mouseover', onDocumentMouseOver);

        return () => {
            controller.off('mouseClick', onMouseClick);
            controller.off('mouseMove', onMouseMove);
            document.removeEventListener('mouseover', onDocumentMouseOver);

            if (hoverTimeoutId.current) {
                clearTimeout(hoverTimeoutId.current);
            }
        };
    }, [controller]);

    const renderInfoToolGroups = () => {
        if (shouldDisplayInfoToolGroups && infoToolGroups) {
            const list = Object.entries(infoToolGroups);
            return (
                <>
                    <ObjectDivider />
                    <NearbyObject
                        title={t('nearbyObjects') + ':'}
                        zoomBtnLabel={t('zoomToAll')}
                        browseBtnLabel={t('browseAll')}
                        zoomBtnIcon={<Icons.ZoomIn />}
                        browseBtnIcon={<Icons.Net />}
                    />
                    {list.map(([key, value], index) => {
                        return (
                            <React.Fragment key={key}>
                                <ToggleObjectController
                                    model={key}
                                    value={value.results}
                                    shouldExpandable={value.allowExpand}
                                    isExpandedByDefault={value.isExpanded}
                                />
                                {index <
                                    Object.keys(infoToolGroups).length - 1 && (
                                    <ObjectDivider />
                                )}
                            </React.Fragment>
                        );
                    })}
                </>
            );
        }
    };

    useEffect(() => {
        let offSetX = 16;
        const navigationBar = document
            .getElementById('navigation-bar')
            ?.getBoundingClientRect();
        offSetX = offSetX + (navigationBar?.width ?? 0);

        if (isLeftMenuOpen || detailedMenu) {
            const leftMenuRect = document
                .getElementById('left-menu')
                ?.getBoundingClientRect();
            offSetX = offSetX + (leftMenuRect?.width ?? 0);
        }
        setOffset(offSetX);
    }, [isInMapView, isLeftMenuOpen, detailedMenu]);

    const gqlType =
        headerResult?.model && isModelSupported(headerResult.model)
            ? getTypeByModel(headerResult.model)
            : null;
    const form = gqlType ? getFormConfig(gqlType) : null;
    const isSupported = gqlType && form;

    const renderObjectPopup = () => (
        <>
            {headerResult && (
                <ObjectPopup data-testid="object-popup">
                    <ObjectPopupItem
                        title={
                            isSupported
                                ? getTranslatedTitle(gqlType)
                                : (headerResult.model ?? '')
                        }
                        tagText={
                            isEmpty(headerResult.identification)
                                ? (headerResult.pk ?? '')
                                : headerResult.identification
                        }
                        iconObject={form?.icon ?? getModelIcon()}
                        more={<Icons.More />}
                        zoom={<Icons.ZoomIn />}
                        data-testid="object-popup-item"
                        onClickAction={() => {
                            setMenuContent(
                                `InfoObject-${headerResult.model}-${headerResult.pk}`,
                                <InfoObject
                                    model={headerResult.model!}
                                    id={headerResult.pk!}
                                />,
                            );
                        }}
                    />
                    {renderInfoToolGroups()}
                </ObjectPopup>
            )}
        </>
    );

    return (
        <>
            <PositionOverlay position={position}>
                {!isInMapView && renderObjectPopup()}
            </PositionOverlay>
            {isInMapView && (
                <StyledFixedObjectPopup $offset={offset}>
                    {renderObjectPopup()}
                </StyledFixedObjectPopup>
            )}
        </>
    );
};

const StyledFixedObjectPopup = styled.div<{
    $offset: number;
}>`
    position: absolute;
    width: 300px;
    top: 16px;
    left: ${(props) => props.$offset}px;
`;
