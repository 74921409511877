import { getCables } from '@apis/keycom';
import { FormConfig, FormGroup } from '@form-configs';

const createConfig = (groups: FormGroup[] = []): FormConfig => ({
    gqlType: 'CableSearch',
    model: 'cablesearch',
    groups,
    functions: {
        get: getCables,
    },
    queryCollection: 'cables',
});

const config = createConfig();

export default config;
