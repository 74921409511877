import { getExchangeAreas } from '@apis/keycom';
import { FormConfig } from '../types';

const config: FormConfig = {
    gqlType: 'ExchangeArea',
    model: 'exchangearea',
    groups: [],
    functions: {
        get: getExchangeAreas,
    },
    queryCollection: 'exchangeAreas',
};

export default config;
