import { FormField, FormGroup } from '@form-configs';

// Utility function to create range-number fields
const createRangeField = (name: string, translationKey?: string): FormField =>
    translationKey
        ? {
              name,
              component: 'range-number',
              filter: { groupname: { eq: translationKey } },
          }
        : {
              name,
              component: 'range-number',
          };

// Utility function to create combobox-multi fields with filters
const createComboField = (name: string, groupname: string): FormField => ({
    name,
    filter: { groupname: { eq: groupname } },
    component: 'combobox-multi',
});

// Commonly used combobox fields
const commonComboFields: (FormField | string)[] = [
    createComboField('locationAccuracy', 'LOCATION_ACCURACY'),
    createComboField('mappingMethod', 'MAPPING_METHOD'),
    createComboField('heightAccuracy', 'H_ACCURACY'),
    createComboField('safetyDistance', 'SAFETY_DISTANCE'),
];

// Commonly used text fields
const commonTextFields: (FormField | string)[] = [
    'identification',
    'createdBy',
    'updatedBy',
    'remarks',
    'surveyAmount',
    'mapper',
];

// groups for search
export const groupsSearch: FormGroup[] = [
    {
        name: 'manholesearch',
        fields: [
            createComboField('type', 'MANHOLE_TYPE'),
            createComboField('owner', 'MANHOLE_OWNER'),
            createComboField('usageState', 'MANHOLE_STATE'),
            createComboField('renter', 'MANHOLE_OWNER'),
            ...commonTextFields,
            createRangeField('scale'),
            createRangeField('angle'),
            ...commonComboFields,
            createComboField('diagramConfirm', 'DIAGRAM_PAPER_SIZE'),
            createRangeField('installYear'),
        ],
    },
    {
        name: 'telecompremisesearch',
        fields: [
            'name',
            createComboField('owner', 'PREM_OWNER'),
            createComboField('usageState', 'PREM_STATE'),
            createComboField('renter', 'PREM_OWNER'),
            ...commonTextFields,
            'technicalInfo',
            createRangeField('capacity'),
            createRangeField('scale'),
            createRangeField('angle'),
            ...commonComboFields,
            createComboField('diagramConfirm', 'DIAGRAM_PAPER_SIZE'),
            createRangeField('install_year', 'installYear'),
            createComboField('deviceRoom', 'PREM_PLACEMENT'),
            createComboField('importance', 'PREM_IMPORTANCE'),
            createComboField('sourceSystem', 'SOURCE_SYSTEM'),
        ],
    },
    {
        name: 'splicesearch',
        fields: [
            ...commonTextFields,
            createRangeField('attenuation'),
            createRangeField('installYear'),
            createRangeField('angle'),
            createRangeField('scale'),
            createComboField('type', 'SPLICE_TYPE'),
            ...commonComboFields,
            createComboField('owner', 'SPLICE_OWNER'),
            createComboField('renter', 'SPLICE_OWNER'),
            createComboField('placement', 'SPLICE_PLACEMENT'),
            createComboField('productName', 'SPLICE_PRODUCT_NAME'),
            createComboField('usageState', 'SPLICE_STATE'),
        ],
    },
    {
        name: 'cablesearch',
        fields: [
            'identification',
            createRangeField('installYear'),
            createRangeField('length'),
            createRangeField('extraLength'),
            createRangeField('totalLength'),
            createRangeField('importance'),
            ...commonTextFields,
            ...commonComboFields,
            createComboField('state', 'CABLE_STATE'),
            createComboField('owner', 'CABLE_OWNER'),
            createComboField('renter', 'CABLE_OWNER'),
            createComboField('installationType', 'CABLE_TYPE'),
            createComboField('technology', 'CABLE_TECHNOLOGY'),
            createComboField('measure', 'CABLE_MEASURE'),
            createComboField('role', 'CABLE_ROLE'),
            'batch',
            createRangeField('totalCapacity'),
            createRangeField('connectedCapacity'),
            createRangeField('usedCapacity'),
            createRangeField('freeCapacity'),
        ],
    },
    {
        name: 'freelinesearch',
        fields: [
            'identification',
            createRangeField('line_width'),
            createRangeField('length'),
            'color',
            'description',
            'created_by',
            'updated_by',
            createComboField('type', 'FREE_LINE_TYPE'),
            createComboField('state', 'FREELINE_STATE'),
        ],
    },
    {
        name: 'conduitsearch',
        fields: [
            ...commonTextFields,
            createRangeField('length'),
            createRangeField('width'),
            createComboField('measure', 'CABLE_MEASURE'),
            ...commonComboFields,
            createRangeField('ductCount'),
            createRangeField('categoryId'),
            createComboField('usageState', 'CONDUIT_STATE'),
            createComboField('conduitType', 'CONDUIT_TYPE'),
            createComboField('profileStatus', 'PROFILE_STATUS'),
            createComboField('surfaceType', 'CONDUIT_SURFACE_TYPE'),
            createComboField('sourceSystem', 'SOURCE_SYSTEM'),
        ],
    },
    {
        name: 'addresssearch',
        fields: [
            'streetno',
            'streetname',
            'building_name',
            'city',
            'municipality',
            'district',
            'province',
            'country',
            'zipcode',
            'createdBy',
            'updatedBy',
            createComboField('usagePurpose', 'BUILDING_USAGE'),
            createComboField('sourceSystem', 'SOURCE_SYSTEM'),
        ],
    },
    {
        name: 'freeareasearch',
        fields: [
            'name',
            'color',
            'created_by',
            'updated_by',
            createComboField('type', 'FREEAREA_TYPE'),
            createComboField('state', 'FREEAREA_STATE'),
            createRangeField('area_size'),
            createRangeField('perimeter'),
        ],
    },
];
