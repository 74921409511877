import { getTxtConstants } from '@apis/keycore';
import { TxtConstantsFilter } from '@generated';
import { useQuery } from '@tanstack/react-query';

export interface UseGetTxtConstantsOptions {
    filter?: TxtConstantsFilter;
}

export function useGetTxtConstants(options: UseGetTxtConstantsOptions) {
    const { groupname } = options.filter || {};

    return useQuery({
        queryKey: ['map', 'txtConstants', { groupname }],
        queryFn: async () => {
            return getTxtConstants({
                groupname: groupname!,
            });
        },
    });
}
