import { FormConfig } from '../types';
import { planGroup } from '../groups';
import { getPoints } from '@apis/keycore';
import { Icons } from '@keypro/2nd-xp';

const config: FormConfig = {
    gqlType: 'Point',
    model: 'point',
    icon: Icons.AddressPoint,
    groups: [
        {
            name: 'objectData',
            fields: [
                {
                    name: 'owner',
                    filter: {
                        groupname: { eq: 'POINT_OBJECT_OWNER' },
                    },
                },
                {
                    name: 'renter',
                    filter: {
                        groupname: { eq: 'POINT_OBJECT_RENTER' },
                    },
                },
                'identification',
                { name: 'description', translationKey: 'pointDescription' },
                'length',
                'install_year',
                'distance',
                {
                    name: 'state',
                    filter: {
                        groupname: { eq: 'POINT_STATE' },
                    },
                },
            ],
        },
        planGroup,
        {
            name: 'common',
            fields: [
                {
                    name: 'location_accuracy',
                    filter: {
                        groupname: { eq: 'LOCATION_ACCURACY' },
                    },
                },
                {
                    name: 'haccuracy',
                    translationKey: 'heightAccuracy',
                    filter: {
                        groupname: { eq: 'H_ACCURACY' },
                    },
                },
                'history_date',
                {
                    name: 'mapping_method',
                    filter: {
                        groupname: { eq: 'MAPPING_METHOD' },
                    },
                },
                'survey_amount',
                'mapper',
            ],
        },
    ],
    functions: {
        get: getPoints,
    },
    queryCollection: 'points',
};

export default config;
