import { getTelecomPremises } from '@apis/keycom';
import { FormConfig, FormGroup } from '@form-configs';

const createConfig = (groups: FormGroup[] = []): FormConfig => ({
    gqlType: 'TelecomPremiseSearch',
    model: 'telecompremisesearch',
    groups,
    functions: {
        get: getTelecomPremises,
    },
    queryCollection: 'telecompremises',
});

const config = createConfig();

export default config;
