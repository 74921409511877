import { GraphQLFilter } from '@apis/utils';
import { getFormConfig } from '@form-configs';

/**
 * Queries the API for the data for the given GraphQL type.
 * @param gqlType The GraphQL type to get data for.
 * @param options Options to pass to the API query.
 * @returns The data for the given GraphQL type.
 */
export const getData = (gqlType: string, filter: GraphQLFilter) => {
    const config = getFormConfig(gqlType);
    return config.functions.get(filter);
};

/**
 * Joins non-empty strings with the given separator.
 * @param items Strings to join.
 * @param separator Separator to use.
 * @returns The joined string.
 */
export const joinNonEmpty = (items: unknown[], separator: string) => {
    return items.filter(Boolean).join(separator);
};

/**
 * Convert string to snake case.
 * @param str String to convert.
 * @returns Converted string.
 */
export const toSnakeCase = (str: string) => {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

/**
 * Convert string to camel case.
 * @param str String to convert.
 * @returns Converted string.
 */
export const toCamelCase = (str: string) => {
    return str.replace(/_./g, (match) => match[1].toUpperCase());
};
