query getManholes($filter: ManholeFilter, $limit: Int, $offset: Int) {
  getManholes(filter: $filter, limit: $limit, offset: $offset) {
    manholes {
      id
      createdBy
      createdTs
      updatedBy
      updatedTs
      owner {id, txt}
      renter {id, txt}
      type {id, txt}
      identification
      installYear
      usageState {id, txt}
      diagramConfirm {id, txt}
      remarks
      address {
        id
        address {
          id
          streetno
          streetname
          streetnameAlt
          district
          city
          province
          municipality
          country
          zipcode
          location
        }
      }
      telecomArea {
        id
        areaSize
        perimeter
      }
      plan {id, name}
      location
      locationAccuracy {id, txt}
      heightAccuracy {id, txt}
      historyDate
      mappingMethod {id, txt}
      safetyDistance {id, txt}
      surveyAmount
      mapper
      rentPaid
      angle
      scale
    }
  }
}
