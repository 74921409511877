import {
    ExchangeArea,
    ExchangeAreaFilter,
    GetExchangeAreasQuery,
} from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetExchangeAreas from './queries/GetExchangeAreas.graphql';

/**
 * Gets the exchange areas.
 * @returns The exchange areas.
 * @throws {Error} If the request fails.
 */
export const getExchangeAreas = async (
    filter?: ExchangeAreaFilter,
): Promise<ExchangeArea[]> => {
    try {
        const response = await sendGraphQLRequest<GetExchangeAreasQuery>(
            GetExchangeAreas,
            {
                filter: filter,
            },
        );

        if (!response.data.getExchangeAreas) {
            throw Error('No data returned');
        }

        return response.data.getExchangeAreas.exchangeAreas?.filter(
            Boolean,
        ) as ExchangeArea[];
    } catch (error) {
        throw Error('Failed to get exchange areas: ' + error);
    }
};
