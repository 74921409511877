import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { LayerSettingsMenu } from '@keypro/2nd-xp';
import { isLayerFavourite, setFavouriteLayer } from './layerHelpers';
import { t } from 'i18next';

interface LayerSettingsPopperProps {
    activeNodeId: string | null;
    handleClose: () => void;
    referenceElement: HTMLElement | null;
    setFavouriteLayersChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LayerSettingsPopper = ({
    activeNodeId,
    handleClose,
    referenceElement,
    setFavouriteLayersChanged,
}: LayerSettingsPopperProps) => {
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(
        null,
    );
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'right-start', // Align the popper to right of the button
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 6], // Add 8px gap between button and popper
                },
            },
        ],
    });

    const handleFavouriteChange = (value: boolean) => {
        setFavouriteLayer(activeNodeId!, value);
        setFavouriteLayersChanged(true);
    };

    return activeNodeId ? (
        <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
        >
            <LayerSettingsMenu
                layerName={activeNodeId}
                handleClose={handleClose}
                headerTitle={t('layerSettings')}
                opacityTitle={t('layerOpacity')}
                favourite={isLayerFavourite(activeNodeId)}
                onFavourite={handleFavouriteChange}
                favouriteTitle={t('showInFavourites')}
                favouriteDescription={t('showLayerInFavouritesDescription')}
            />
        </div>
    ) : null;
};
