import { FreeLine, GetFreeLinesQuery } from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetFreeLines from './queries/GetFreeLines.graphql';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';

/**
 * Gets the freelines.
 * @returns {Promise<Plan[]>} The freelines.
 * @throws {Error} If the request fails.
 */
export const getFreeLines = async (
    filter: GraphQLFilter,
): Promise<FreeLine[]> => {
    try {
        const payload = !isFilterEmpty(filter)
            ? {
                  filter: filter,
              }
            : {
                  filter: null,
                  limit: 1,
              };
        const response = await sendGraphQLRequest<GetFreeLinesQuery>(
            GetFreeLines,
            payload,
        );

        if (!response.data.getFreeLines) {
            throw Error('No data returned');
        }

        return response.data.getFreeLines.freeLines?.filter(
            Boolean,
        ) as FreeLine[];
    } catch (error) {
        throw Error('Failed to get free lines: ' + error);
    }
};
