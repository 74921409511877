import React, { useState, useEffect, useRef, useContext } from 'react';
import { SortableList, MapContext } from '@keypro/2nd-xp';
import { MapViewListItem } from './mapViewListItem';
import { MapView } from './MapViewMenu';

interface MapViewListProps {
    /** Search term */
    search: string;
    /** List of MapViews */
    mapViews: MapView[];
    /** Function to set the list of MapViews */
    setMapViews: React.Dispatch<React.SetStateAction<MapView[]>>;
    /** Function to save the list of MapViews */
    saveMapViews: (newViews: MapView[]) => void;
    editingView: string | null;
    setEditingView: React.Dispatch<React.SetStateAction<string | null>>;
}

/**
 * Component for displaying a list of MapViews
 */
export const MapViewList = ({
    search,
    mapViews,
    setMapViews,
    saveMapViews,
    editingView,
    setEditingView,
}: MapViewListProps): JSX.Element => {
    const controller = useContext(MapContext)!;
    const [displayedViews, setDisplayedViews] = useState<MapView[]>(mapViews);
    const previousActiveElement = useRef<HTMLDivElement | null>(null);

    const handleOrderChange = (newOrder: string[]) => {
        const newViews = newOrder.map(
            (id) => mapViews.find((view) => view.id === id)!,
        );
        saveMapViews(newViews);
    };

    const handleSave = (title: string, id: string) => {
        const newViews = mapViews.map((view) =>
            view.id === id ? { ...view, name: title } : view,
        );
        setMapViews(newViews);
    };

    const deleteView = (id: string) => {
        const newViews = mapViews.filter((view) => view.id !== id);
        setMapViews(newViews);
    };

    const handleClick = (
        id: string,
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        const view = mapViews.find((view) => view.id === id);
        if (view) {
            controller.setMapParams(view.data);
        }

        if (previousActiveElement.current) {
            previousActiveElement.current.classList.remove('active');
        }

        event.currentTarget.classList.add('active');
        previousActiveElement.current = event.currentTarget;
    };

    useEffect(() => {
        setDisplayedViews(mapViews);
    }, [mapViews]);

    // Update the displayed views when the search term changes
    useEffect(() => {
        if (search) {
            setDisplayedViews(
                mapViews.filter((view) =>
                    view.name.toLowerCase().includes(search.toLowerCase()),
                ),
            );
        } else {
            setDisplayedViews(mapViews);
        }
    }, [search, mapViews]);

    return (
        <SortableList
            style={{
                padding: '0px 4px',
                boxSizing: 'border-box',
            }}
            onOrderChange={(newOrder: string[]) => handleOrderChange(newOrder)}
        >
            {displayedViews.map((view) => (
                <MapViewListItem
                    key={view.id}
                    name={view.name}
                    date={view.date || ''}
                    id={view.id}
                    onClick={(e) => handleClick(view.id, e)}
                    onSave={(name: string) => handleSave(name, view.id)}
                    onDelete={() => deleteView(view.id)}
                    editing={editingView === view.id}
                    setEditingView={setEditingView}
                    data-testid={`mapview-menu-item-${view.id}`}
                />
            ))}
        </SortableList>
    );
};
