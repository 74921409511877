import { getManholes } from '@apis/keycom';
import { FormConfig } from '../types';
import {
    addressGroup,
    commonGroup,
    planGroup,
    telecomAreaGroup,
} from '../groups';
import { Icons } from '@keypro/2nd-xp';

const config: FormConfig = {
    gqlType: 'Manhole',
    model: 'manhole',
    icon: Icons.Manhole,
    groups: [
        {
            name: 'objectData',
            fields: [
                {
                    name: 'owner',
                    filter: {
                        groupname: { eq: 'MANHOLE_OWNER' },
                    },
                },
                {
                    name: 'renter',
                    filter: {
                        groupname: { eq: 'MANHOLE_OWNER' },
                    },
                },
                {
                    name: 'type',
                    filter: {
                        groupname: { eq: 'MANHOLE_TYPE' },
                    },
                },
                'identification',
                'installYear',
                {
                    name: 'usageState',
                    filter: {
                        groupname: { eq: 'MANHOLE_STATE' },
                    },
                },
                {
                    name: 'diagramConfirm',
                    component: 'radio',
                    filter: {
                        groupname: { eq: 'MANHDIAG_CONFIRMED' },
                    },
                },
                {
                    name: 'remarks',
                    component: 'textarea',
                },
            ],
        },
        addressGroup,
        telecomAreaGroup,
        planGroup,
        {
            name: 'common',
            fields: [
                ...commonGroup.fields,
                {
                    name: 'safetyDistance',
                    filter: {
                        groupname: { eq: 'SAFETY_DISTANCE' },
                    },
                },
            ],
        },
    ],
    functions: {
        get: getManholes,
    },
    queryCollection: 'manholes',
};

export default config;
