import { sendGraphQLRequest, buildTenantURL } from '@utils';
import { GetMapConfigQuery, Layer } from '@generated';
import GetMapConfig from './queries/GetMapconfig.graphql';
import { MapConfig, LayerGroup } from '@keypro/2nd-xp';

// Definition for layer switcher config that comes from backend
interface LayerSwitcherConfig {
    name: string;
    text: string;
    layer?: string;
    checked?: number;
    expanded?: number;
    children?: LayerSwitcherConfig[];
    weight?: number;
    info?: string;
    allowDrag?: boolean;
    allowDrop?: boolean;
    check_input?: boolean;
    tristate?: boolean;
    tristatevalue?: number;
    isRadio?: boolean;
    hidden?: boolean;
    leaf?: boolean;
    render_tree_icon?: boolean;
}

const parseV1StringToObject = (config: string) => {
    return config.split(',').reduce((acc, option) => {
        const [key, value] = option.split(':').map((str) => str.trim());
        let parsedValue;
        try {
            parsedValue = JSON.parse(value);
        } catch {
            parsedValue = value;
        }
        return {
            ...acc,
            [key.replace(/['"]/g, '')]: parsedValue,
        };
    }, {});
};

const processLayerSwitcherConfig = (
    layerGroup: LayerSwitcherConfig,
): LayerGroup => {
    const processedLayerGroup: LayerGroup = {
        id: layerGroup.name,
        text: layerGroup.text,
        layer: layerGroup.layer ?? '',
        checked: layerGroup.checked === 1 || layerGroup.tristatevalue === 1,
        expanded: layerGroup.expanded === 1,
        children: [],
    };

    if (layerGroup.children && layerGroup.children.length > 0) {
        processedLayerGroup.children = layerGroup.children.map(
            processLayerSwitcherConfig,
        );
    }

    return processedLayerGroup;
};

type LayerConfig = MapConfig['layer_configs'][number];

const buildLayerUrl = (url: string) => {
    if (!url.includes(',') && url.includes('/maps/')) {
        if (url.startsWith('http')) {
            return buildTenantURL(new URL(url).pathname);
        } else {
            return buildTenantURL(url);
        }
    } else {
        return url;
    }
};

export const fetchMapConfig = async (): Promise<MapConfig> => {
    const response = await sendGraphQLRequest<GetMapConfigQuery>(GetMapConfig);
    const layerSwitcherConfig =
        response.data.getMapConfig?.mapConfig?.layer_switcher_configs;
    const mapConfig = response.data.getMapConfig
        ?.mapConfig as unknown as MapConfig;

    // fix for missing options_v2 and params_v2 on layers
    // shouldn't be needed if layer options and params in backend are updated to use v2
    mapConfig.layer_configs = mapConfig.layer_configs.map((layer: Layer) => {
        return {
            ...layer,
            url: buildLayerUrl(layer.url as string),
            options_v2: parseV1StringToObject(layer.options as string),
            params_v2: {
                ...parseV1StringToObject(layer.params as string),
                mode: 'map',
            },
        } as unknown as LayerConfig;
    });

    // find if any base layer is visible
    const visibleBaseLayer = mapConfig.layer_configs.find(
        (layer) => layer.options_v2.isBaseLayer && layer.options_v2.visibility,
    );
    if (!visibleBaseLayer) {
        // find mml1 layer and set it as visible
        const mml1Layer = mapConfig.layer_configs.find(
            (layer) => layer.name === 'mml1',
        );
        if (mml1Layer) {
            mml1Layer.options_v2.visibility = true;
        } else {
            // if mml1 layer is not found, set first base layer as visible
            const firstBaseLayer = mapConfig.layer_configs.find(
                (layer) => layer.options_v2.isBaseLayer,
            );
            if (firstBaseLayer) {
                firstBaseLayer.options_v2.visibility = true;
            }
        }
    }

    // find all baselayers and set their order to -999
    mapConfig.layer_configs.forEach((layer) => {
        if (layer.options_v2.isBaseLayer) {
            layer.order = -999;
        }
    });

    if (layerSwitcherConfig) {
        mapConfig.layer_groups = layerSwitcherConfig.children.map(
            processLayerSwitcherConfig,
        );
    }
    return mapConfig;
};
