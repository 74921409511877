import { GetPlansQuery, Plan, PlanFilter } from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetPlans from './queries/GetPlans.graphql';

/**
 * Gets the plans.
 * @returns {Promise<Plan[]>} The plans.
 * @throws {Error} If the request fails.
 */
export const getPlans = async (filter?: PlanFilter): Promise<Plan[]> => {
    try {
        const response = await sendGraphQLRequest<GetPlansQuery>(GetPlans, {
            filter: filter,
        });

        if (!response.data.getPlans) {
            throw Error('No data returned');
        }

        return response.data.getPlans.plans?.filter(Boolean) as Plan[];
    } catch (error) {
        throw Error('Failed to get plans: ' + error);
    }
};
