import { Icons, styled } from '@keypro/2nd-xp';
import backgroundImage from '@assets/images/login-bg.webp';

const StyledLoadingPage = styled.div`
    background-image: url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;

    svg {
        width: 100px;
        height: 100px;
    }
`;

export const LoadingPage = (): JSX.Element => {
    return (
        <StyledLoadingPage>
            <Icons.Spinner />
        </StyledLoadingPage>
    );
};
