query getServiceAreas($filter: ServiceAreasFilter) {
  getServiceAreas(filter: $filter) {
    serviceAreas {
      id
      name
      location
      category { id, txt }
      createdBy
      createdTs
      updatedBy
      updatedTs
      areaSize
      perimeter
      remarks
    }
  }
}
