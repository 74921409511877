import { Conduit, GetConduitsQuery } from '@generated';
import GetConduits from './queries/GetConduits.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';

/**
 * Gets the conduits.
 * @returns The conduits.
 * @throws {Error} If the request fails.
 */
export const getConduits = async (filter: GraphQLFilter) => {
    try {
        const payload = !isFilterEmpty(filter)
            ? {
                  filter: filter,
              }
            : {
                  filter: null,
                  limit: 1,
              };
        const response = await sendGraphQLRequest<GetConduitsQuery>(
            GetConduits,
            payload,
        );

        if (!response.data.getConduits) {
            throw Error('No data returned');
        }

        return response.data.getConduits.conduits?.filter(Boolean) as Conduit[];
    } catch (error) {
        throw Error('Failed to get conduits: ' + error);
    }
};
