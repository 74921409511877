query getPoles($filter: PolesFilter) {
  getPoles(filter: $filter) {
     poles{
      id
      pole_number
      line_part
      checkout_date
      install_year
      length
      remarks
      survey_amount
      mapper
      history_date
      location
      angle
      scale
      created_by
      created_ts
      updated_by
      updated_ts
      owner {id}
      renter {id}
      pole_type {id}
      usage_state {id}
      material {id}
      impregnant {id}
      stanchion {id}
      decay_class {id}
      station_decay_class {id}
      plan {id, name}
      telecom_area {id, areaSize, perimeter}
      location_accuracy {id}
      height_accuracy {id}
      mapping_method {id}
    }
  }
}
