query getDocuments($filter: DocumentsFilter) {
  getDocuments(filter: $filter) {
    documents {
      id
      filename
      description
      url
      downloadUrl
      createdBy
      createdTs
      updatedBy
      updatedTs
      location
      angle
      fileSize
      type {
        id
        description
        type
        category { id, txt }
      }
    }
  }
}
