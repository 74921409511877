import {
    ServiceArea,
    ServiceAreasFilter,
    GetServiceAreasQuery,
} from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetServiceAreas from './queries/GetServiceAreas.graphql';

/**
 * Gets the service areas.
 * @returns {Promise<ServiceArea[]>} The service areas.
 * @throws {Error} If the request fails.
 */
export const getServiceAreas = async (
    filter?: ServiceAreasFilter,
): Promise<ServiceArea[]> => {
    try {
        const response = await sendGraphQLRequest<GetServiceAreasQuery>(
            GetServiceAreas,
            {
                filter: filter,
            },
        );

        if (!response.data.getServiceAreas) {
            throw Error('No data returned');
        }

        return response.data.getServiceAreas.serviceAreas?.filter(
            Boolean,
        ) as ServiceArea[];
    } catch (error) {
        throw Error('Failed to get service areas: ' + error);
    }
};
