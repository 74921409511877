import { useMobile } from '@hooks/responsive';
import { Button, Icons, SideMenu, styled } from '@keypro/2nd-xp';
import { useLeftMenu } from '@stores';

export const FullwidthSideMenu = styled(SideMenu)`
    width: 100%;
`;

const SideMenuHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: ${({ theme }) => theme.fonts['18px Bold']};
    font-weight: 700;
    max-height: 32px;
`;

/**
 * The props of InfoObjectSetting component.
 * @param title The title of the setting.
 * @param children The children of the setting.
 */
export interface ResponsiveSideMenuProps {
    title?: string;
    children?: React.ReactNode;
}
export const ResponsiveSideMenu = ({
    title,
    children,
}: ResponsiveSideMenuProps) => {
    const isMobile = useMobile();
    const Component = isMobile ? FullwidthSideMenu : SideMenu;
    const { toggleMenu } = useLeftMenu();

    return (
        <Component data-testid={`navigationbar-menu-${title}`}>
            <SideMenuHeader>
                {title}
                <Button
                    kind="ghost"
                    onClick={() => toggleMenu()}
                    data-testid="navigationbar-menu-closeBtn"
                >
                    <Icons.Cross />
                </Button>
            </SideMenuHeader>
            {children}
        </Component>
    );
};

/**
 * Placeholder menu for buttons in the navigation bar.
 */
export const DummyLeftMenu = (): JSX.Element => {
    const { menuContentId } = useLeftMenu();
    return (
        <ResponsiveSideMenu title={`${menuContentId} WIP`}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                To be implemented...
            </div>
        </ResponsiveSideMenu>
    );
};
