import { Button, Icons, styled } from '@keypro/2nd-xp';
import { t } from 'i18next';

/**
 * The props of ActionsContainer component.
 * @param model The model of the object.
 */
export interface ActionsContainerProps {
    isOpen?: boolean;
}

/**
 * The ActionsContainer component displays action list.
 * @param model The model of the object.
 * @returns The InfoObjectContainer component
 */
export const ActionsContainer = ({ isOpen }: ActionsContainerProps) => {
    return (
        <StyledActionsContainer $isOpen={isOpen}>
            <StyledAction>
                <Button iconPosition="left" kind="ghost" label={t('zoomTo')}>
                    <Icons.ZoomIn />
                </Button>
            </StyledAction>
            <StyledDivider />
            <StyledAction>
                <Button
                    iconPosition="left"
                    kind="ghost"
                    label={t('attributeTable')}
                >
                    <Icons.Table />
                </Button>
                <Button
                    iconPosition="left"
                    kind="ghost"
                    label="Manhole diagram"
                >
                    <Icons.Diagram />
                </Button>
                <Button
                    iconPosition="left"
                    kind="ghost"
                    label={t('showVersionHistory')}
                >
                    <Icons.Version />
                </Button>
            </StyledAction>
            <StyledDivider />
            <StyledAction>
                <Button iconPosition="left" kind="ghost" label={t('modify')}>
                    <Icons.Modify2 />
                </Button>
                <Button iconPosition="left" kind="ghost" label={t('duplicate')}>
                    <Icons.Duplicate />
                </Button>
            </StyledAction>
            <StyledDivider />
            <StyledAction>
                <Button
                    iconPosition="left"
                    kind="ghost"
                    label={t('saveToList')}
                >
                    <Icons.BookmarkSave />
                </Button>
                <Button iconPosition="left" kind="ghost" label={t('pin')}>
                    <Icons.Pin />
                </Button>
                <Button
                    iconPosition="left"
                    kind="ghost"
                    label={t('annotateObject')}
                >
                    <Icons.Annotation />
                </Button>
            </StyledAction>
            <StyledDivider />
            <StyledAction>
                <StyledTitle>{t('connectionsManagement')}</StyledTitle>
                <Button kind="ghost" label={t('physical')} />
                <Button kind="ghost" label={t('logical')} />
                <Button kind="ghost" label={t('ductConnections')} />
            </StyledAction>
            <StyledDivider />
            <StyledAction>
                <StyledTitle>{t('export')}</StyledTitle>
                <Button
                    kind="ghost"
                    iconPosition="left"
                    label={t('billOfMaterials')}
                >
                    <Icons.ExcelFile />
                </Button>
                <Button
                    kind="ghost"
                    iconPosition="left"
                    label={t('ductConnectionReport')}
                >
                    <Icons.ExcelFile />
                </Button>
                <Button
                    kind="ghost"
                    iconPosition="left"
                    label={t('advancedExport')}
                >
                    <Icons.AdvancedFile />
                </Button>
            </StyledAction>
            <StyledDivider />
            <StyledAction>
                <Button
                    iconPosition="left"
                    kind="ghost"
                    label={t('deleteObject')}
                >
                    <Icons.Trash />
                </Button>
            </StyledAction>
        </StyledActionsContainer>
    );
};

const StyledActionsContainer = styled.div<{ $isOpen?: boolean }>`
    display: ${(props) => (props.$isOpen ? 'block' : 'none')};
    width: 256px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.neutral['50']};
    background-color: ${(props) => props.theme.colors.neutral['10']};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    position: absolute;
    z-index: 100;
    top: 34px;
    right: -10px;
    &::after {
        display: inline-block;
        width: 14px;
        height: 4px;
        position: absolute;

        transform: translate(-50%, -10px);
        top: auto;
        left: 90%;
        bottom: 100%;

        content: url('data:image/svg+xml;utf8,<svg viewBox="0 0 15 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 2.00024L1.5 6.00024L13.5 6.00024L7.5 2.00024Z" fill="${(
            props,
        ) =>
            encodeURIComponent(
                props.theme.colors.neutral['10'],
            )}" /><path d="M0.5 5.50024L1.5 5.50024L7.5 1.50024L13.5 5.50024L14.5 5.50024" stroke="${(
            props,
        ) => encodeURIComponent(props.theme.colors.neutral['50'])}" /></svg>');
    }
`;

const StyledAction = styled.div`
    padding: 8px;
    & > button {
        padding-top: 6px;
        padding-bottom: 6px;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        ${(props) => props.theme.fonts['14px Regular']};
        color: ${(props) => props.theme.colors.neutral['90']};
        & > svg {
            width: 20px;
            height: 20px;
        }
    }
`;

const StyledDivider = styled.div`
    height: 2px;
    background-color: ${(props) => props.theme.colors.neutral['20']};
    width: 100%;
`;

const StyledTitle = styled.p`
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    ${(props) => props.theme.fonts['12px Medium Uppercase']};
    color: ${(props) => props.theme.colors.neutral['80']};
`;
