import { getPoles } from '@apis/keycom';
import { FormConfig } from '../types';
import {
    commonGroupSnakeCase,
    planGroup,
    telecomAreaGroupSnakeCase,
} from '../groups';
import { Icons } from '@keypro/2nd-xp';

const config: FormConfig = {
    gqlType: 'Pole',
    model: 'pole',
    icon: Icons.Fat,
    groups: [
        {
            name: 'objectData',
            fields: [
                {
                    name: 'owner',
                    filter: {
                        groupname: { eq: 'POLE_OWNER' },
                    },
                },
                {
                    name: 'renter',
                    filter: {
                        groupname: { eq: 'POLE_OWNER' },
                    },
                },
                'line_part',
                'pole_number',
                'branch_pole_number',
                'install_year',
                { name: 'checkout_date', component: 'date' },
                'length',
                {
                    name: 'stanchion',
                    filter: {
                        groupname: { eq: 'POLE_STANCHION' },
                    },
                },
                {
                    name: 'pole_type',
                    translationKey: 'type',
                    filter: {
                        groupname: { eq: 'POLE_TYPE' },
                    },
                },
                {
                    name: 'usage_state',
                    filter: {
                        groupname: { eq: 'POLE_TYPE' },
                    },
                },
                {
                    name: 'material',
                    filter: {
                        groupname: { eq: 'POLE_MATERIAL' },
                    },
                },
                {
                    name: 'impregnant',
                    filter: {
                        groupname: { eq: 'POLE_IMPREGNANT' },
                    },
                },
                {
                    name: 'decay_class',
                    filter: {
                        groupname: { eq: 'POLE_DECAY_CLASS' },
                    },
                },
                {
                    name: 'station_decay_class',
                    translationKey: 'stanchionDecayClass',
                    filter: {
                        groupname: { eq: 'POLE_DECAY_CLASS' },
                    },
                },
                {
                    name: 'remarks',
                    component: 'textarea',
                },
            ],
        },
        telecomAreaGroupSnakeCase,
        planGroup,
        commonGroupSnakeCase,
    ],
    functions: {
        get: getPoles,
    },
    queryCollection: 'poles',
};

export default config;
