import { getSplices } from '@apis/keycom';
import { FormConfig } from '../types';
import { addressGroup, planGroup, telecomAreaGroup } from '../groups';
import { Icons } from '@keypro/2nd-xp';

const config: FormConfig = {
    gqlType: 'Splice',
    model: 'splice',
    icon: Icons.Splice,
    groups: [
        {
            name: 'objectData',
            fields: [
                {
                    name: 'owner',
                    filter: {
                        groupname: { eq: 'SPLICE_OWNER' },
                    },
                },
                {
                    name: 'renter',
                    filter: {
                        groupname: { eq: 'SPLICE_OWNER' },
                    },
                },
                'identification',
                {
                    name: 'usageState',
                    filter: {
                        groupname: { eq: 'SPLICE_STATE' },
                    },
                },
                {
                    name: 'type',
                    filter: {
                        groupname: { eq: 'SPLICE_TYPE' },
                    },
                },
                {
                    name: 'productName',
                    filter: {
                        groupname: { eq: 'SPLICE_PRODUCT_NAME' },
                    },
                },
                {
                    name: 'placement',
                    filter: {
                        groupname: { eq: 'SPLICE_PLACEMENT' },
                    },
                },
                'attenuation',
                'installYear',
                {
                    name: 'remarks',
                    component: 'textarea',
                },
            ],
        },
        addressGroup,
        telecomAreaGroup,
        planGroup,
        {
            name: 'common',
            fields: [
                {
                    name: 'locationAccuracy',
                    filter: {
                        groupname: { eq: 'LOCATION_ACCURACY' },
                    },
                },
                {
                    name: 'heightAccuracy',
                    filter: {
                        groupname: { eq: 'H_ACCURACY' },
                    },
                },
                'historyDate',
                {
                    name: 'mappingMethod',
                    filter: {
                        groupname: { eq: 'MAPPING_METHOD' },
                    },
                },
                'survey_amount',
                {
                    name: 'safetyDistance',
                    filter: {
                        groupname: { eq: 'SAFETY_DISTANCE' },
                    },
                },
                'mapper',
            ],
        },
    ],
    functions: {
        get: getSplices,
    },
    queryCollection: 'splices',
};

export default config;
