import { InfoObjectContainer } from './InfoObjectMenu/InfoObjectContainer';

/**
 * The props of InfoObject component.
 * @param model The model of the object.
 */
export interface InfoObjectProps {
    /** Info object model */
    model: string;
    /** Info object id */
    id: string;
}

/**
 * The InfoObject component displays menu object on right of the map.
 * @param model The model of the object.
 * @returns
 */
export const InfoObject = ({ model, id }: InfoObjectProps) => {
    return <InfoObjectContainer model={model} id={id} />;
};
